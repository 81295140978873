<template>
    <v-dialog
        v-model="showDialog"
        max-width="600px"
        persistent>
        <template v-slot:activator="{on: dialog, attrs}">
            <v-tooltip bottom open-delay="750">
                <template v-slot:activator="{on: tooltip}">
                    <v-btn  
                        v-bind="attrs"
                        v-on="{...tooltip, ...dialog}"
                        class="ml-2 mr-2"
                    >
                        <v-icon>mdi-map-legend</v-icon>
                    </v-btn>
                </template>
                <span>Export Annotations and Legend Report</span>
            </v-tooltip>
        </template>
        <v-card>
            <v-card-title>
                <span class="headline">Export Annotations and Legend</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row v-if="!loading">
                        <v-col cols="12">
                            <v-combobox
                                v-model="exportType"
                                :items="exportTypeOptions"
                                label="Export Type"
                                multiple
                            ></v-combobox>
                        </v-col>
                        <v-col cols="12" v-if="exportType.includes('People')">
                            <v-combobox
                                v-model="exportEmployeeType"
                                :items="exportEmployeeTypeOptions"
                                label="Employee Type"
                                item-text="name"
                                multiple
                                return-object
                            >
                            </v-combobox>
                        </v-col>
                        <v-col cols="12">
                            <v-select
                                v-model="exportFloorPlan"
                                :items="exportFloorPlanOptions"
                                label="Floor Plan"
                                item-text="__str__"
                                return-object
                            ></v-select>
                        </v-col>
                        <v-col cols="12">
                            <v-combobox
                                v-model="exportDivision"
                                :items="exportDivisionOptions"
                                item-text="name"
                                label="Division(s)"
                                multiple
                                return-object
                            ></v-combobox>
                        </v-col>
                    </v-row>
                    <v-row v-if="loading">
                        <v-col cols="12">
                            Generating report...
                        </v-col>
                        <v-progress-linear
                            indeterminate
                            rounded
                            height="6"
                        ></v-progress-linear>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="reset()">Close</v-btn>
                <v-btn
                    color="blue darken-1"
                    :disabled="loading"
                    text
                    @click="handleExport"
                >Export</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import axios from 'axios';
import { mapGetters, mapActions } from "vuex";

export default {
    name: "ExportAnnotationDialog",
    data() {
        return {
            showDialog: false,
            loading: false,
            exportDivision: [],
            exportType: ['Computers', 'Printers', 'People'],
            exportTypeOptions: ['Computers', 'Printers', 'People'],
            exportEmployeeType:[],
            exportFloorPlan: null,
            poll: null,
            errorMsg: '',
        };
    },
    computed: {
        ...mapGetters({
            currentFloorPlan: 'space/getCurrentFloorPlan',
            currentFloorPlanAnnotation: 'space/getCurrentFloorPlanAnnotation',
            divisionOptions: 'population/getDivisionList',
            employeeTypeOptions: 'population/getEmployeeTypes',
            isDraft: 'space/getIsDraft',
        }),
        exportFloorPlanOptions() {
            return [this.currentFloorPlanAnnotation, {__str__: 'All Floor Plans', pk: -1}];
        },
        exportDivisionOptions() {
            return [{id: -1, name: 'All Divisions'}, ...this.divisionOptions];
        },
        exportDivisionIds() {
            return this.exportDivision.map(d => d.id);
        },
        exportEmployeeTypeOptions() {
            return [{pk: -1, name: 'All Person types'}, {pk: 'researcher', name: 'Researcher'}, {pk: 'unknown', name: 'Unknown'}, ...this.employeeTypeOptions]
        },
        exportEmployeeTypeVals: {
            get(){
                if (this.exportEmployeeType.length == 0) return [0];
                return this.exportEmployeeType.map(d => d.name);
            },
            set(value){
                this.value = value;
            }
        }
    },
    methods: {
        ...mapActions({
            'loadDivisions': 'population/loadDivisions',
            'loadEmployeeTypes': 'population/loadEmployeeTypes'
        }),
        reset() {
            this.showDialog = false;
            this.loading = false;
            this.errorMsg = '';
            this.exportType = this.exportTypeOptions;
            this.exportFloorPlan = this.currentFloorPlanAnnotation;
            this.exportDivision = [this.exportDivisionOptions[0]];
            this.exportEmployeeType = [this.exportEmployeeTypeOptions[0]];
        },
        async handleExport() {
            this.loading = true;
            let valid = this.validate();

            if (!valid) {
                this.loading = false;
                this.$store.commit('setSnack', { message: this.errorMsg, color: 'error' });
                this.errorMsg = '';
                return;
            }

            if (!this.exportType.includes('People')) {
                this.exportEmployeeTypeVals = [0];
            }
            const url = `/inventory/export_annotation_legend/${this.exportFloorPlan.pk}/${this.exportDivisionIds}/${this.exportType}/${this.exportEmployeeTypeVals}`
            
            const response = await axios.get(url);

            let task_id = response.data.task_id;

            this.poll = this.pollAsyncResults(task_id);

        },
        async pollAsyncResults(task_id){
            let url = `/inventory/check_async_task_result/${task_id}`
            let response;
            try {
                response = await axios.get(url);
            } catch (e) {
                clearTimeout(this.poll);
                let message = '';
                if (e.response.status == 500){
                    message = `Error generating report. Please contact Surgery IT with this task id: ${e.response.data.task_id}.`;
                } else if (e.response.status == 404) {
                    message = e.response.data.result.error_message;
                }
                this.$store.commit('setSnack', { message: message, color: 'error', timeout: '30000' });
                this.loading = false;
                return;

            }
    
            if (response.status !== 202) {
                clearTimeout(this.poll);

                let s3_url = response.data.result.location;

                const link = document.createElement('a');
                link.href = s3_url;
                link.setAttribute('download', 'temp.pdf'); //or any other extension
                document.body.appendChild(link);
                link.click();
                this.loading = false;
            } else {
                let self = this;
                this.poll = setTimeout(function() { self.pollAsyncResults(task_id) }, 3000);
            }
        },
        validate() {
            if (this.exportType.length == 0) {
                this.errorMsg = 'Form invalid, please pick an export type.'
            }

            if (this.exportDivision.length == 0) {
                this.errorMsg = 'Form invalid, please pick a division.';
            }

            if (!this.exportFloorPlan) {
                this.errorMsg = 'Form invalid, no floor plan selected.';
            }

            if (this.currentFloorPlanAnnotation.pk == -1) {
                if (this.exportFloorPlan.__str__ !== 'All Floor Plans') {
                    if (!this.isDraft) {
                        this.errorMsg = 'This floor plan is empty. Report can not be generated.';
                    } else {
                        this.errorMsg = 'There are unsaved changes. Please save the floor plan before generating a report';
                    }
                }
                if (this.exportFloorPlan.__str__ == 'All Floor Plans' && this.isDraft) {
                    this.errorMsg = 'There are unsaved changes. Please save this floor plan before generating a report.';
                }
            }

            if (this.exportType.includes('People') && this.exportEmployeeTypeVals.length == 0) {
                this.errorMsg = 'Form invalid, no person type selected.'
            }

            if (this.errorMsg) return false;

            return true;
        }
    },
    created() {
        this.exportFloorPlan = this.currentFloorPlanAnnotation;
        this.exportDivision = [this.exportDivisionOptions[0]];
        this.exportEmployeeType = [this.exportEmployeeTypeOptions[0]];
        this.exportType = this.exportTypeOptions;
        this.loadDivisions();
        this.loadEmployeeTypes();
    }
};
</script>