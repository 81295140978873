<template>
    <v-dialog v-model="showDialog" persistent max-width="600px">
        <template v-slot:activator="{on: dialog, attrs}">
            <v-tooltip :disabled="!isDraft" bottom open-delay="750">
                <template v-slot:activator="{on: tooltip}">
                    <v-btn
                        v-bind="attrs"
                        v-on="{...tooltip, ...dialog}"
                        class="ml-2"
                        color="primary"
                        :disabled="!isDraft || loading"
                    >
                        <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                </template>
                <span>Save</span>
            </v-tooltip>
        </template>
        <v-card>
            <v-card-title>
                <span class="headline">Save Annotation</span>
            </v-card-title>
            <v-card-text>
                <v-alert
                    v-if="currentAnnotation && multiUserAnnotations.includes(currentAnnotation.pk)"
                    type="warning"
                    outlined
                >
                    <strong>This annotation is currently open by others</strong>
                </v-alert>
                <v-container v-if="!saveLoading">
                    <v-row no-gutters>
                        <v-col cols="12">
                            <v-textarea
                                v-model="notes"
                                solo
                                label="Notes"
                                hide-details
                                autofocus
                            ></v-textarea>
                        </v-col>
                    </v-row>
                    <v-checkbox 
                        v-model="saveState"
                        label="Save view state (zoom, position, etc.)"
                        hide-details
                    ></v-checkbox>
                </v-container>
                <v-container v-if="saveLoading">
                    <v-row>
                        <v-col cols="12">
                            Saving...
                        </v-col>
                        <v-progress-linear
                            indeterminate
                            rounded
                            height="6"
                        ></v-progress-linear>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="showDialog = false">Close</v-btn>
                <v-btn
                    color="blue darken-1"
                    @click="saveAnnotation"
                    text
                    :loading="saveLoading"
                    :disabled="saveLoading"
                >Save Annotation</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";

export default {
    name: "SaveDialog",
    data() {
        return {
            showDialog: false,
            loading: false,
            notes: '',
            saveState: false,
            saveLoading: false
        };
    },
    computed: {
        ...mapGetters({
            isDraft: 'space/getIsDraft',
            multiUserAnnotations: 'space/getMultiUserAnnotations',
            currentAnnotation: 'space/getCurrentFloorPlanAnnotation',
            isFiltered: 'space/getFilterState'
        })
    },
    methods: {
        saveAnnotation() {
            this.loading = true;
            this.saveLoading = true;
            if (this.isFiltered) {
                this.$emit('filterSaveAnnotation');
                
            } else {
                this.$store.dispatch('space/setSaveAnnotation', {
                    prepareSave: true,
                    notes: this.notes,
                    saveState: this.saveState,
                });
            }
            
            // this.showDialog = false;
            // this.loading = false;
        }
    }
};
</script>