<template>
    <div style="">
        <v-avatar
            v-if="showIcon"
            :color="itemType.color"
            size="25"
            class="mr-2"
        >
            <v-icon v-text="itemType.icon" dark small />
        </v-avatar>
        <template v-if="itemType.name === 'Room'">
            {{ item.customData.room.building.short_name }}-{{ item.customData.room.floor }}-{{ item.customData.room.room }}
        </template>
        <template v-else-if="itemType.group === 'Endpoints'">
            {{ item.customData.endpoint.hostname }}
        </template>
        <template v-else-if="itemType.name === 'Person'">
            {{ item.customData.person.name }}
        </template>
        <template v-if="item.notes">
            <div class="caption grey--text ml-9 mt-n2">{{ item.notes }}</div>
        </template>
    </div>
</template>
<script>

    export default {
        name: 'AnnotationItem',
        props: {
            item: {
                type: Object,
                default() {
                    return {}
                }
            },
            showIcon: {
                type: Boolean,
                default: true
            }
        },
        computed: {
            itemType() {
                return this.item.customData ? this.item.customData.type : this.item;
            }
        },
        data: () => ({
        }),
    }
</script>