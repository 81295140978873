<template>
    <v-dialog v-model="showDialog" persistent max-width="600px">
        <template v-slot:activator="{ on: dialog, attrs }">
            <v-menu offset-y>
                <template v-slot:activator="{on: menu}">
                    <v-tooltip bottom open-delay="750">
                        <template v-slot:activator="{on: tooltip}">
                            <v-btn 
                                v-bind="attrs" 
                                v-on="{...tooltip, ...menu}" 
                                class="ml-2 mr-2"
                                color="primary"
                            >
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>Add Item</span>
                    </v-tooltip>
                </template>
                <v-list dense>
                    <v-list-item-group>
                        <v-list-item
                            v-for="(t, i) in floorPlanAnnotationItemTypes"
                            :key="i"
                            v-on="dialog"
                            @click.stop="setAnnotationType(t)"
                        >
                            <v-icon left v-text="t.icon" />
                            <v-list-item-content class="ml-1">
                                <v-list-item-title v-text="t.name" />
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-menu>
        </template>
        <AddEndpointDialog
            v-if="curAnnotationType.group === 'Endpoints'"
            :curAnnotationType="curAnnotationType"
            @closeDialog="showDialog = false"
        />
        <AddPersonDialog
            v-if="curAnnotationType.name === 'Person'"
            :curAnnotationType="curAnnotationType"
            @closeDialog="showDialog = false"
        />
        <AddRoomDialog
            v-if="curAnnotationType.name === 'Room'"
            :curAnnotationType="curAnnotationType"
            ref="addRoomDialog"
            @closeDialog="showDialog = false"
        />
    </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';

import AddEndpointDialog from '@/components/space/AddEndpointDialog';
import AddPersonDialog from '@/components/space/AddPersonDialog';
import AddRoomDialog from '@/components/space/AddRoomDialog';

export default {
    name: "AddAnnotationItemDialog",
    components: {
        AddEndpointDialog,
        AddPersonDialog,
        AddRoomDialog
    },
    data() {
        return {
            showDialog: false,
            loading: false,
            curAnnotationType: {},
        };
    },
    computed: {
        ...mapGetters({
            itemCount: 'space/getItemCount',
            currentFloorPlanAnnotation: 'space/getCurrentFloorPlanAnnotation',
            floorPlanAnnotationItemTypes: 'space/getFloorPlanAnnotationItemTypes',
            floorPlanAnnotationItems: 'space/getFloorPlanAnnotationItems',
            editAnnotationItem: 'space/getEditAnnotationItem',
        }),
    },
    methods: {
        setAnnotationType(t) {
            this.curAnnotationType = t;
        },
    },
    watch: {
        async editAnnotationItem(val) {
            if (val.editing) {
                await this.setAnnotationType({name: 'Room'});
                this.showDialog = true;
                await this.$nextTick();
                this.$refs.addRoomDialog.loadEdit();
            }
        }
    },
    mounted() {
        this.$store.dispatch('space/loadFloorPlanAnnotationItemTypes');
    }
};
</script>