<template>
    <div id="pspdfkit" style="width: 100%; height: 600px" />
</template>
<script>
    import PSPDFKit from 'pspdfkit';
    import { mapGetters } from 'vuex';

    export default {
        name: 'CartographyPDFWidget',
        data () {
            return {
                pdfToolBarItems: [{"type": "pan"}, {"type": "zoom-out"}, {"type": "zoom-in"}, {"type": "zoom-mode"}, {"type":"print"}],
                pspdfInstance: null,
                loadedAnnotations: false,
                fullDelete: false,
                isRedrawing: false,
                deletedAnn: false
            }
        },
        computed: {
            ...mapGetters({
                addAnnotation: 'space/getAddAnnotation',
                saveAnnotation: 'space/getSaveAnnotation',
                currentFloorPlan: 'space/getCurrentFloorPlan',
                currentFloorPlanAnnotation: 'space/getCurrentFloorPlanAnnotation',
                hoverItem: 'space/getHoverItem',
                lastAnnotationSaved: 'space/getLastAnnotationSaved',
                currentFloorPlanAnnotationItemTypes: 'space/getCurrentFloorPlanAnnotationItemTypes',
                annotationItems: 'space/getFloorPlanAnnotationItems',
                itemCount: 'space/getItemCount',
                currentAnnotationExport: 'space/getCurrentAnnotationExport',
                applyAnnotationExport: 'space/getApplyAnnotationExport',
                editAnnotationItem: 'space/getEditAnnotationItem',
                user: 'user',
                isFiltered: 'space/getFilterState',
            }),

        },
        methods: {
            async loadFloorPlan(floorPlan, instantJSON=undefined) {

                this.loadedAnnotations = false;
                this.deletedAnn = false; 

                PSPDFKit.unload('#pspdfkit'); // unload any existing containers

                if (!floorPlan) return;
                
                const vm = this;

                const instance = await PSPDFKit.load({
                    locale: 'en',
                    instantJSON: instantJSON,
                    baseUrl: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_PSPDFKIT_BASEURL : process.env.VUE_APP_PSPDFKIT_BASEURL_DEV,
                    container: "#pspdfkit",
                    toolbarItems: this.pdfToolBarItems,
                    styleSheets: [`${this.baseUrl}/static/css/pspdfkit.css`],
                    document: floorPlan.pdf,
                    licenseKey: `${process.env.NODE_ENV === 'production' ? process.env.VUE_APP_PSPDFKIT_LICENSE_KEY : process.env.VUE_APP_PSPDFKIT_LICENSE_KEY_DEV}`,
                    customRenderers: {
                        Annotation: ({ annotation }) => {

                            function selectAnnotation(event) {
                                event.stopImmediatePropagation();
                                instance.setSelectedAnnotation(annotation.id);
                            }

                            if (annotation instanceof PSPDFKit.Annotations.ImageAnnotation) {
                                const node = instance.contentDocument.createElement("div");

                                node.style.cssText = "position: absolute; width: 100%; height: 100%; pointer-events: all; cursor: pointer";

                                const mouseoverListener = async function() {
                                    if (vm.hoverItem === null ||  (vm.hoverItem.name != annotation.name)) {

                                        vm.updateHoverItem(annotation);
                                    }
                                };

                                node.addEventListener("mouseover", mouseoverListener);
                                node.addEventListener("pointerdown", selectAnnotation, {
                                    capture: true
                                });
                                
                                return {
                                    node,
                                    append: true,
                                    onDisappear: () => {
                                        node.removeEventListener("pointerdown", selectAnnotation, {
                                            capture: true
                                        });
                                    }
                                };
                            }
                        }
                    }
                });

                if (instantJSON) this.loadedAnnotations = true;

                instance.addEventListener('annotations.willSave', () => {
                    this.handleAnnotationChange();
                });
                
                instance.addEventListener('annotations.delete', async (deletedAnnotations) => {
                    this.handleAnnotationDelete(deletedAnnotations);
                });

                instance.addEventListener('annotations.focus', (event) => {
                    this.handleItemFocus(event);
                });

                /* HANDLES NON-IMAGE (RECTANGLE ETC) ANNOTATION MOUSEOVERS */
                instance.contentDocument.addEventListener('mouseover', async ({ target }) => {
                    this.handleMouseover(target);
                });

                instance.contentDocument.addEventListener('dblclick', async ({ target }) => {
                    this.handleDoubleClick(target);
                });
                
                


                this.pspdfInstance = instance;
                await this.$store.dispatch('space/setPSPDFInstance', instance);

                if (!instantJSON) {
                    const annotationIds = await this.addAnnotationItems();
                    await this.$store.dispatch('space/addAnnotationItemIds', annotationIds.map(x => x.id));
                }
            },

            async deleteAnnotations(instance) {
                const pagesAnnotations = await Promise.all(
                    Array.from({ length: instance.totalPageCount }).map((_, pageIndex) => instance.getAnnotations(pageIndex))
                );
                await Promise.all(pagesAnnotations.flatMap(pageAnnotations => pageAnnotations.map(annotation => 
                    instance.delete(annotation.id)
                )));
            },

            async setInitialViewState() {
                const state = this.pspdfInstance.viewState;
                if (state.zoom === 'AUTO') return;

                const el = this.pspdfInstance.contentDocument.querySelector('.PSPDFKit-Scroll');

                if (!this.currentFloorPlanAnnotation.meta.view_state) {
                    await this.pspdfInstance.setViewState(state.set('zoom', 'AUTO'));
                    el.scrollLeft = 0;
                    el.scrollTop = 0;
                } else {
                    const vs = this.currentFloorPlanAnnotation.meta.view_state;
                    const newState = state.set('zoom', vs.zoom);
                    await this.pspdfInstance.setViewState(newState);
                    
                    el.scrollLeft = vs.scroll[0];
                    el.scrollTop = vs.scroll[1];
                }
            },

            async addAnnotationItems(returnAnnotations=true, specificItems=[], deleteAnnotations=true) {

                const instance = this.pspdfInstance;
                
                let returnAnnotationList = [];

                let items;
                let itemCountStart = 1;

                if (!specificItems.length) {
                    items = this.annotationItems;
                    this.setInitialViewState();
                    this.loadedAnnotations = false;
                } else {
                    items = specificItems;
                    itemCountStart = this.itemCount + 1;
                }

                if (deleteAnnotations && this.itemCount > 0) await this.deleteAnnotations(instance);

                for (let ai of items) {
                    let annotation;
                    const meta = ai.meta || ai;

                    
                    if (meta.customData.type.name === 'Room') {

                        /* RECTANGLE ANNOTATIONS */

                        if (!meta.bbox) meta.bbox = [50, 50, 60, 60];

                        const rectAnnotation = new PSPDFKit.Annotations.RectangleAnnotation({
                            pageIndex: 0,
                            boundingBox: new PSPDFKit.Geometry.Rect({
                                width: meta.bbox[2],
                                height: meta.bbox[3],
                                top: meta.bbox[1],
                                left: meta.bbox[0]
                            }),
                            customData: meta.customData,
                            strokeWidth: 2
                        });

                        annotation = await instance.create(rectAnnotation);

                    } else {

                        /* ALL OTHER ANNOTATIONS (CIRCLE W/ TEXT) */

                        if (!meta.bbox) meta.bbox = [50, 50, 25, 25];

                        const color = this.currentFloorPlanAnnotationItemTypes[meta.customData.type.name].color;

                        let file = await fetch(this.drawCircle(itemCountStart, {color: color}));

                        meta.icon = file.url; //    icon for legend

                        file = await file.blob();
                        
                        let attachmentId = await instance.createAttachment(file);

                        let annObj = {
                            imageAttachmentId: attachmentId,
                            pageIndex: 0,
                            contentType: 'image/png',
                            boundingBox: new PSPDFKit.Geometry.Rect({
                                width: meta.bbox[2],
                                height: meta.bbox[3],
                                top: meta.bbox[1],
                                left: meta.bbox[0]
                            }),
                            customData: meta.customData
                        };
                        
                        const imgAnnotation = new PSPDFKit.Annotations.ImageAnnotation(annObj);

                        annotation = await instance.create(imgAnnotation);
                        
                        itemCountStart++;
                    }

                    annotation = annotation[0];

                    if (returnAnnotations) returnAnnotationList.push(annotation);
                }

                if (deleteAnnotations) {
                    this.$store.dispatch('space/setCurrentAnnotationExport', {
                        instantJSON: {
                            annotations: returnAnnotationList
                        },
                        initialLoad: true,
                    });
                    this.loadedAnnotations = true;
                }
                
                return returnAnnotationList;
            },


            async handleAnnotationChange() {

                if (this.loadedAnnotations) {
                    this.$emit('isLoading', {save: false, loading: true});
                    this.$store.dispatch('space/setIsDraft', true);

                    const instantJSON = await this.pspdfInstance.exportInstantJSON();

                    const el = this.pspdfInstance.contentDocument.querySelector('.PSPDFKit-Scroll');
                    let tempItems = this.annotationItems;
                    tempItems.forEach(ann => {
                        let x = instantJSON.annotations.find(x => x.id == ann.id);
                        if (x) ann.meta.bbox = x.bbox;
                    });
                    this.$store.dispatch('space/setFloorPlanAnnotationItems', tempItems);
                    this.$store.dispatch('space/setCurrentAnnotationExport', {
                        instantJSON: instantJSON,
                        viewState: {
                            zoom: this.pspdfInstance.viewState.zoom,
                            scroll: [el.scrollLeft, el.scrollTop]
                        },
                        items: this.annotationItems
                    });
                    this.$emit('isLoading', {save: false, loading: false});
                }
            },

            async handleAnnotationDelete(deletedAnnotations) {
                if (!this.isRedrawing && this.loadedAnnotations) {
                    for (const ann of deletedAnnotations) {
                        await this.deleteAndRedrawAnnotations(ann);
                    }
                    this.handleAnnotationChange();
                }
            },

            async handleMouseover(target) {
                const closestAnnotation = target.closest('.PSPDFKit-Annotation');
                if (target && (closestAnnotation || target.classList.contains('PSPDFKit-Annotation'))) {

                    const allAnnotations = await this.pspdfInstance.getAnnotations(0);

                    const annotation = allAnnotations.find(a => a.id === closestAnnotation.dataset.annotationId);

                    this.updateHoverItem(annotation);
                }
            },

            async handleDoubleClick(target) {
                const pNode = target.parentNode.parentNode;
                const closestAnnotation = pNode.querySelector('.PSPDFKit-Rectangle-Annotation');
                if (closestAnnotation) {
                    const allAnnotations = await this.pspdfInstance.getAnnotations(0);
                    const annotation = allAnnotations.find(a => a.id === closestAnnotation.dataset.annotationId);
                    this.$store.dispatch('space/setEditAnnotationItem', {annotation: annotation, editing: true});
                }
            },

            handleItemFocus(event) {
                this.$store.dispatch('space/setFocusedAnnotationItem', event.annotation);
            },

            async updateHoverItem(annotation) {
                let hoverItemData = {
                    name: annotation.name,
                    customData: annotation.customData
                }
                if (annotation.imgDataURL) {
                    hoverItemData.icon = annotation.imgDataURL;
                } else if (annotation.imageAttachmentId) {
                    const imgData = await this.pspdfInstance.getAttachment(annotation.imageAttachmentId);
                    const imgDataURL = await this.readBlobAsDataURL(imgData);
                    hoverItemData.icon = imgDataURL;
                }

                this.$store.dispatch('space/setHoverItem', hoverItemData);
            },

            setLoadedAnnotations(val) {
                this.loadedAnnotations = val;
            },

            async deleteAndRedrawAnnotations(ann) {
                this.isRedrawing = true;
                await this.$store.dispatch('space/removeFloorPlanAnnotationItem', ann);

                // // add annotation items and remap ids
                
                // const annotationIds = await this.addAnnotationItems();
                // await this.$store.dispatch('space/addAnnotationItemIds', annotationIds.map(x => x.id));
                this.deletedAnn = true;

                this.isRedrawing = false;
            },
            
            async filterAnnotationItems(payload){
                await this.resetFilterAnnotationItems();
                let updateList = [];
                const instance = this.pspdfInstance;
                let allAnnotations = await instance.getAnnotations(0);

                payload.forEach(ann => {
                    allAnnotations = allAnnotations.filter(a => {
                        return a.id !== ann.id;
                    });
                });

                allAnnotations.forEach(a => {
                    updateList.push(a.set('hidden', true));
                });

                await instance.update(updateList);
            },

            async resetFilterAnnotationItems(){
                let updateList = [];
                const instance = this.pspdfInstance;
                let allAnnotations = await instance.getAnnotations(0);
                allAnnotations.forEach(ann => {
                    updateList.push(ann.set('hidden', false));
                });
                await instance.update(updateList);
            },

            setSelectedAnnotation(annotation) {
                this.updateHoverItem({
                    name: annotation.name,
                    customData: annotation.meta.customData,
                    imgDataURL: annotation.icon
                });
                this.pspdfInstance.setSelectedAnnotation(annotation.id);
                this.$emit('scrollTop')
            }

        },
        watch: {
            async addAnnotation(payload) {
                if (payload) {

                    // check for new annotation type
                    if (payload.annotationType) {
                        await this.$store.dispatch('space/addFloorPlanAnnotationItemType', payload.annotationType);
                        payload = payload.annotation;
                    }

                    // add new annotation
                    const annotation = await this.addAnnotationItems(true, [payload], false);

                    // set id
                    payload.id = annotation[0].id;

                    // select newly added annotation
                    this.pspdfInstance.setSelectedAnnotation(annotation[0].id);

                    // set hoverItem for newly created annotations
                    if (payload.setHover) {
                        this.updateHoverItem(annotation[0]);
                        delete payload.setHover;
                    }

                    if (payload.meta.customData.type.name === 'Room') {
                        payload.noCount = true;
                    }

                    // clear addAnnotation in store and increment itemCount
                    this.$store.dispatch('space/processAddAnnotation', payload);
                }
            },

            async saveAnnotation(payload) {

                if (payload.prepareSave) {
                    // add annotation items and remap ids only if annotations were deleted
                    this.$emit('isLoading', {save: true, loading: true});
                    if(this.deletedAnn){
                        const annotationIds = await this.addAnnotationItems();
                        await this.$store.dispatch('space/addAnnotationItemIds', annotationIds.map(x => x.id));
                        this.deletedAnn = false;
                    }

                    const instantJSON = await this.pspdfInstance.exportInstantJSON();

                    // omitted to allow saving empty floorplans
                    // if (!instantJSON['annotations']) {
                    //     // this.$store.commit('setSnack', {message: 'No annotations to be saved', color: ''});
                    //     // this.$store.dispatch('space/setSaveAnnotation', false);
                    //     // this.$emit('isLoading', {save: true, loading: false});
                    //     // return;
                        
                    // }

                    // remove prepareSave property
                    delete payload.prepareSave;

                    // add other properties
                    payload.floorPlan = this.currentFloorPlan.pk;
                    payload.instantJSON = instantJSON;

                    if (payload.saveState) {
                        const el = this.pspdfInstance.contentDocument.querySelector('.PSPDFKit-Scroll');
                        payload.viewState = {
                            zoom: this.pspdfInstance.viewState.zoom,
                            scroll: [el.scrollLeft, el.scrollTop]
                        }
                    }
                    
                    // is this the first annotation?
                    if (this.currentFloorPlanAnnotation.pk === -1) payload.firstAnnotation = true

                    await this.$store.dispatch('space/setSaveAnnotation', payload);

                    if (this.lastAnnotationSaved === payload) {
                        await this.$emit('isLoading', {save: true, loading: false});
                        await this.$nextTick(() => {
                            this.$store.commit('setSnack', { message: 'Successfully saved annotation', color: 'success' });
                            // route to new annotationId
                            this.$router.push({'name': 'cartographyFloorPlanAnnotation', params: {
                                floorPlanId: this.currentFloorPlan.pk,
                                annotationId: this.currentFloorPlanAnnotation.pk
                            }});
                        });
                    } else {

                    this.$store.commit('setSnack', { message: 'Error saving annotation', color: 'error' });
                    }

                    this.$emit('isLoading', {save: true, loading: false});
                }
            },

            async applyAnnotationExport() {
                // load annotations
                await this.loadFloorPlan(this.currentFloorPlan, this.applyAnnotationExport.instantJSON);

                if (this.applyAnnotationExport.viewState.zoom !== 'AUTO') {
                    // load viewstate
                    const state = this.pspdfInstance.viewState;
                    const newState = state.set('zoom', this.applyAnnotationExport.viewState.zoom);
                    await this.pspdfInstance.setViewState(newState);

                    // set scroll
                    // TODO: this seems to be causing error in at least FF:
                    // "This site appears to use a scroll-linked positioning effect. This may not work well with asynchronous panning; see https://developer.mozilla.org/docs/Mozilla/Performance/ScrollLinkedEffects for further details and to join the discussion on related tools and features!"
                    // Functionality still works fine, but might be a better solution out there.
                    const el = this.pspdfInstance.contentDocument.querySelector('.PSPDFKit-Scroll');
                    el.scrollLeft = this.applyAnnotationExport.viewState.scroll[0];
                    el.scrollTop = this.applyAnnotationExport.viewState.scroll[1];
                }

                this.$store.dispatch('space/setIsDraft', true);
                //Set true so that both users can move annotations
                this.loadedAnnotations = true;
            },

            async editAnnotationItem(val) {
                if (!val.editing && val.annotation) {
                    
                    this.pspdfInstance.updateAnnotation(val.annotation);
                    this.updateHoverItem(val.annotation);
                }
            }
        }
    }
</script>