<template>
    <v-slide-y-transition>
        <v-card
            class="mt-3 mb-2"
            v-show="showFilter"
            flat
            outlined
        >
            <v-card-text>
                <v-row no-gutters>
                    <v-col>
                        <span class="overline">Item Type</span>
                        <template v-for="(t, i) in annotationItemTypes">
                            <v-checkbox
                                v-model="filterPayload.item[t.name.split(' ')[0].toLowerCase()]"
                                :key="i"
                                :label="t.name"
                                class="ma-0"
                                hide-details
                                return-object
                                :readonly="readonly[t.name.split(' ')[0].toLowerCase()]"
                            />
                        </template>
                    </v-col>
                    <!-- <v-col>
                        <span class="overline">Computer Type</span>
                        <v-checkbox
                            label="Desktop"
                            class="ma-0"
                            v-model="filterPayload.comp.desktop"
                            hide-details
                        /> 
                        <v-checkbox
                            label="Thin Client"
                            class="ma-0"
                            v-model="filterPayload.comp.thinclient"
                            
                            hide-details
                        /> 
                        <v-checkbox
                            label="Laptop (experimental)"
                            class="ma-0"
                            v-model="filterPayload.comp.laptop"
                            
                            hide-details
                        /> 
                    </v-col> -->
                    <v-col>
                        <span class="overline">Operating System</span>
                        <v-checkbox
                            label="Windows 10"
                            class="ma-0"
                            v-model="filterPayload.os.windows"
                            hide-details
                        />
                        <!-- <v-checkbox
                            label="ThinOS"
                            class="ma-0"
                            v-model="filterPayload.os.thinos"
                            hide-details
                        /> -->
                        <v-checkbox
                            label="Mac OS"
                            class="ma-0"
                            v-model="filterPayload.os.mac"
                            hide-details
                        />
                    </v-col>
                    <v-col>
                        <span class="overline">Person Type</span>
                        <v-checkbox
                            label="Unknown"
                            class="ma-0"
                            v-model="filterPayload.person.unknown"
                            hide-details
                        />
                        <v-checkbox
                            label="Officer"
                            class="ma-0"
                            v-model="filterPayload.person.officer"
                            hide-details
                        />
                        <v-checkbox
                            label="ServiceCorp"
                            class="ma-0"
                            v-model="filterPayload.person.servcorp"
                            hide-details
                        />
                        <v-checkbox
                            label="Faculty"
                            class="ma-0"
                            v-model="filterPayload.person.faculty"
                            hide-details
                        />
                        <v-checkbox
                            label="Fellow"
                            class="ma-0"
                            v-model="filterPayload.person.fellow"
                            hide-details
                        />
                        <v-checkbox
                            label="Other"
                            class="ma-0"
                            v-model="filterPayload.person.other"
                            hide-details
                        />
                        <v-checkbox
                            label="Researcher"
                            class="ma-0"
                            v-model="filterPayload.person.research"
                            hide-details
                        />
                        <v-checkbox
                            label="Uni Only"
                            class="ma-0"
                            v-model="filterPayload.person.uni"
                            hide-details
                        />
                        <v-checkbox
                            label="Research"
                            class="ma-0"
                            v-model="filterPayload.person.research"
                            hide-details
                        />
                        <v-checkbox
                            label="Visitor/Observer"
                            class="ma-0"
                            v-model="filterPayload.person.visitor"
                            hide-details
                        />
                        <v-checkbox
                            label="Visiting Student International"
                            class="ma-0"
                            v-model="filterPayload.person.visitStudent"
                            hide-details
                        />
                    </v-col>
                    <v-col>
                        <span class="overline">Division</span>
                        <template v-for="(item, key) of division_groups">
                            <v-checkbox
                                :label="key"
                                :key="key"
                                class="ma-0"
                                v-model="filterPayload.division.parent[key]"
                                @change="addChildDivisionToFilterPayload({key: key, items: item})"
                                hide-details
                            />
                            <template v-if="filterPayload.division.parent[key]">
                                <v-checkbox 
                                    v-for="div in item"
                                        :label="div.short_name"
                                        :key="div.short_name"
                                        class="ml-2 mt-0"
                                        v-model="filterPayload.division.child[key][div.name]"
                                        :value="div"
                                        hide-details
                                    />
                            </template>
                        </template>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="justify-center">
                <!-- <v-spacer></v-spacer> -->
                <v-btn
                    color="blue darken-1"
                    text
                    :loading="loading"
                    @click="resetFilter"
                >Reset</v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    :loading="loading"
                    @click="handleFilter"
                >Apply</v-btn>
                
            </v-card-actions>
        </v-card>
    </v-slide-y-transition>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
//const endpointTypeOptions = ['Computer', 'Thin Client', 'Printer / MFP'];

export default {
    name: 'FilterWidget',
    props: {
        showFilter: {
            type: Boolean
        }
    },
    data() {
        return {
            loading: false,
            readonly: {
                person: false,
                computer: false,
                printer: false,
                thin: false,
                room: false,
            },
        };
    },
    computed: {
        ...mapGetters({
            annotationItemTypes: 'space/getFloorPlanAnnotationItemTypes',
            initialFilter: 'space/getInitialFilter',
            filterPayload: 'space/getFilterPayload',
            floorPlanAnnotationItems: 'space/getFloorPlanAnnotationItems'
        }),
        emptyPayload(){
            let empty = true;
            for(let prop in this.filterPayload){
                let arr = Object.values(this.filterPayload[prop]);
                if(arr.some(x => x)){
                    empty = false;
                    break;
                }
            }
            return empty;
        },
        division_groups(){
            let divisions = {};
            for (let item of this.floorPlanAnnotationItems){
                let customData = item.meta.customData;

                if (customData.room) {
                    continue;
                }
                
                let type;
                if (customData.person) {
                    type = 'person'
                } else if (customData.endpoint) {
                    type = 'endpoint'
                }

                let div = customData[type].division;

                if(!div.parent && !(div.short_name in divisions)) {
                    divisions[div.short_name] = [];
                } else if (div.parent) {
                    if(!(div.parent_name in divisions)){
                        divisions[div.parent_name] = []
                    }

                    let dupe = false;
                    for (let child of divisions[div.parent_name]){
                        if (child.name == div.name) {
                            dupe = true;
                            break;
                        }
                    }

                    if (!dupe) {
                        divisions[div.parent_name].push(div);
                    }
                    
                }
            }
            
            // sort the children divisions by name
            for (let div in divisions){
                let children = divisions[div]
                divisions[div] = children.sort(this.dynamicSort('short_name'))
            }

            // sort the parents
            let sorted_divs = {}
            Object.keys(divisions)
                .sort()
                .forEach(function(v) {
                    sorted_divs[v] = divisions[v]
                });
            
            return sorted_divs

        }
    },
    methods: {
        ...mapActions({
        }),
        
        async handleFilter() {
            this.$emit('filterAnnotation', {emptyPayload: this.emptyPayload});
        },
        async resetFilter(){
            this.$emit('resetFilter', {emptyPayload: this.emptyPayload});
        },
        addChildDivisionToFilterPayload(payload){
            this.$store.dispatch('space/addChildDivisionToFilterPayload', payload);
        },
        dynamicSort(property){
            var sortOrder = 1;
            if (property[0] === "-") {
                sortOrder = -1;
                property = property.substr(1);
            }
            return function (a, b) {
                var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
                return result * sortOrder;
            };
        },
    },
    watch: {
        'filterPayload.os': {
            handler: function(n){
                let arr = Object.values(n);
                if (arr.some(x => x)){   
                    if(!this.filterPayload.item.computer){
                        // this.selected.item.computer = true;
                        this.$store.dispatch('space/setChangeFilterPayloadProp', {prop: 'item.computer', value: true})
                    }
                    this.readonly.computer = true;
                } else {
                    this.readonly.computer = false;
                }
            },
            deep: true
        },
        'filterPayload.person': {
            handler: function(n){
                let arr = Object.values(n);
                if (arr.some(x => x)){   
                    if(!this.filterPayload.item.person){
                        this.$store.dispatch('space/setChangeFilterPayloadProp', {prop: 'item.person', value: true})
                    }
                    this.readonly.person = true;
                } else {
                    this.readonly.person = false;
                }
            },
            deep: true
        },

    }
};
</script>