import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
var attrs = ref.attrs;
return [_c(VTooltip,{attrs:{"disabled":!_vm.isDraft,"bottom":"","open-delay":"750"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"primary","disabled":!_vm.isDraft || _vm.loading}},'v-btn',attrs,false),Object.assign({}, tooltip, dialog)),[_c(VIcon,[_vm._v("mdi-content-save")])],1)]}}],null,true)},[_c('span',[_vm._v("Save")])])]}}]),model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"headline"},[_vm._v("Save Annotation")])]),_c(VCardText,[(_vm.currentAnnotation && _vm.multiUserAnnotations.includes(_vm.currentAnnotation.pk))?_c(VAlert,{attrs:{"type":"warning","outlined":""}},[_c('strong',[_vm._v("This annotation is currently open by others")])]):_vm._e(),(!_vm.saveLoading)?_c(VContainer,[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextarea,{attrs:{"solo":"","label":"Notes","hide-details":"","autofocus":""},model:{value:(_vm.notes),callback:function ($$v) {_vm.notes=$$v},expression:"notes"}})],1)],1),_c(VCheckbox,{attrs:{"label":"Save view state (zoom, position, etc.)","hide-details":""},model:{value:(_vm.saveState),callback:function ($$v) {_vm.saveState=$$v},expression:"saveState"}})],1):_vm._e(),(_vm.saveLoading)?_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_vm._v(" Saving... ")]),_c(VProgressLinear,{attrs:{"indeterminate":"","rounded":"","height":"6"}})],1)],1):_vm._e()],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.showDialog = false}}},[_vm._v("Close")]),_c(VBtn,{attrs:{"color":"blue darken-1","text":"","loading":_vm.saveLoading,"disabled":_vm.saveLoading},on:{"click":_vm.saveAnnotation}},[_vm._v("Save Annotation")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }