<template>
    <v-card>
        <v-card-title>
            <span class="headline">Add Person</span>
        </v-card-title>
        <v-card-text>
            <v-container>
                <v-alert
                    dense
                    outlined
                    :type="hasBlockingIssues ? 'error' : 'warning'"
                    v-if="issues.length"
                >
                    <ul>
                        <li v-for="(issue, i) in issues" :key="i" v-html="issue.issue"></li>
                    </ul>
                </v-alert>
                <v-form v-model="formValid" ref="addPersonForm">
                    <SearchPeople
                        v-model="person"
                        label="Search people"
                        ref="searchPeopleWidget"
                        @input="validate"
                        ldap-populate
                        employee-populate
                        :employee-populate-create-button="false"
                        :hide-details="false"
                    />
                    <v-select
                        v-if="currentFloorPlan.building"
                        label="Building"
                        :value="currentFloorPlan.building"
                        item-text="name"
                        :items="[currentFloorPlan.building]"
                        disabled
                    />
                    <v-text-field
                        v-if="currentFloorPlan.floor"
                        label="Floor"
                        :value="currentFloorPlan.floor"
                        disabled
                    />
                    <v-text-field
                        v-model="personLocation.room"
                        label="Room"
                        :rules="$rules.required"
                    />
                </v-form>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="reset">Close</v-btn>
            <v-btn
                color="blue darken-1"
                text
                :loading="loading"
                :disabled="!allowAdd"
                @click="add"
            >Add Person</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import SearchPeople from '@/components/population/SearchPeople';

export default {
    name: "AddPersonDialog",
    props: {
        curAnnotationType: {
            type: Object
        }
    },
    components: {
        SearchPeople
    },
    data() {
        return {
            formValid: false,
            loading: false,
            person: {},
            personLocation: {},
            issues: [],
        };
    },
    computed: {
        ...mapGetters({
            itemCount: 'space/getItemCount',
            currentAnnotation: 'space/getCurrentFloorPlanAnnotation',
            currentFloorPlan: 'space/getCurrentFloorPlan',
            annotationItemTypes: 'space/getFloorPlanAnnotationItemTypes',
            annotationItems: 'space/getFloorPlanAnnotationItems',
        }),
        hasBlockingIssues() {
            return this.issues.some(x => x.blocking === true);
        },
        allowAdd() {
            if (this.hasBlockingIssues) return false;
            return (!this.loading && this.formValid && Object.keys(this.person).length);
        }
    },
    methods: {
        reset() {
            this.person = {};
            this.personLocation = {};
            this.$refs.searchPeopleWidget.clear();
            this.$refs.addPersonForm.resetValidation();
            this.issues = [];
            this.$emit('closeDialog');
        },
        async add() {
            this.loading = true;
            
            const newAnnotation = {
                setHover: true,
                meta: {
                    customData: {
                        type: this.curAnnotationType
                    }
                }
            }

            this.personLocation = {...this.personLocation, ...(({building, floor}) => ({building, floor}))(this.currentFloorPlan)};

            // if person.employee_id is blank we need to generate one
            if (!this.person.employee_id) {
                const response = await axios.post(`/population/new_employee_profile/`, this.person);
                this.person = response.data;
            }
            newAnnotation.name = `person${this.person.employee_id}`;

            // now rename employee_id to id for downstream compatibility
            delete Object.assign(this.person, {id: this.person.employee_id}).employee_id;
            
            newAnnotation.meta.customData.person = this.person;
            newAnnotation.meta.customData.person.location = this.personLocation;

            this.$store.dispatch('space/setAddAnnotation', {
                annotationType: this.curAnnotationType,
                annotation: newAnnotation
            });
            
            this.reset();
            
            this.loading = false;
        },
        addIssue(issue) {
            if (!this.issues.some(x => x.issue === issue.issue)) this.issues.push(issue);
        },
        async validate() {
            this.issues = [];
            this.loading = true;

            // 1. Check if person already exists on annotation
            if (this.annotationItems.some(x => x.meta.customData.person && x.meta.customData.person.id === this.person.employee_id)) {
                this.addIssue({issue: `Person already exists on current floor plan annotation`, blocking: true});
            }

            // Check if person is on a different floor plan
            if (this.person && this.person.employee_id) {
                const response = await axios.get(`/space/check_duplicate_person/${this.person.employee_id}/${this.currentFloorPlan.pk}/`);
                if (response.data.duplicate) {

                    this.addIssue({issue: `${this.person.name} is already on a different floor plan. (${response.data.fpa.__str__})`, blocking: false});
                }
            }
            
            // TODO: 2. Check various issues on the server

            this.loading = false;

        }
    }
};
</script>