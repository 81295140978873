import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
var attrs = ref.attrs;
return [_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c(VTooltip,{attrs:{"bottom":"","open-delay":"750"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"ml-2 mr-2",attrs:{"color":"primary"}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c(VIcon,[_vm._v("mdi-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Add Item")])])]}}],null,true)},[_c(VList,{attrs:{"dense":""}},[_c(VListItemGroup,_vm._l((_vm.floorPlanAnnotationItemTypes),function(t,i){return _c(VListItem,_vm._g({key:i,on:{"click":function($event){$event.stopPropagation();return _vm.setAnnotationType(t)}}},dialog),[_c(VIcon,{attrs:{"left":""},domProps:{"textContent":_vm._s(t.icon)}}),_c(VListItemContent,{staticClass:"ml-1"},[_c(VListItemTitle,{domProps:{"textContent":_vm._s(t.name)}})],1)],1)}),1)],1)],1)]}}]),model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[(_vm.curAnnotationType.group === 'Endpoints')?_c('AddEndpointDialog',{attrs:{"curAnnotationType":_vm.curAnnotationType},on:{"closeDialog":function($event){_vm.showDialog = false}}}):_vm._e(),(_vm.curAnnotationType.name === 'Person')?_c('AddPersonDialog',{attrs:{"curAnnotationType":_vm.curAnnotationType},on:{"closeDialog":function($event){_vm.showDialog = false}}}):_vm._e(),(_vm.curAnnotationType.name === 'Room')?_c('AddRoomDialog',{ref:"addRoomDialog",attrs:{"curAnnotationType":_vm.curAnnotationType},on:{"closeDialog":function($event){_vm.showDialog = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }