import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
var attrs = ref.attrs;
return [_c(VTooltip,{attrs:{"bottom":"","open-delay":"750"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"ml-2 mr-2"},'v-btn',attrs,false),Object.assign({}, tooltip, dialog)),[_c(VIcon,[_vm._v("mdi-printer")])],1)]}}],null,true)},[_c('span',[_vm._v("Print Legend")])])]}}]),model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"mb-2"},[_c('span',{staticClass:"headline"},[_vm._v("Print Legend")])]),_c(VCardSubtitle,[_vm._v(" Please allow popups for this site from your browser. ")]),_c(VCardText,[_c(VContainer,[(!_vm.loading)?_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_c(VCombobox,{attrs:{"items":_vm.exportTypeOptions,"label":"Export Type","multiple":""},model:{value:(_vm.exportType),callback:function ($$v) {_vm.exportType=$$v},expression:"exportType"}})],1),_c(VCol,{attrs:{"cols":"6"}},[_c(VSelect,{attrs:{"items":_vm.cols,"label":"Number of Columns","return-object":""},model:{value:(_vm.numCols),callback:function ($$v) {_vm.numCols=$$v},expression:"numCols"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":"Title"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1)],1):_vm._e(),(_vm.loading)?_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_vm._v(" Generating legend... ")]),_c(VProgressLinear,{attrs:{"indeterminate":"","rounded":"","height":"6"}})],1):_vm._e()],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.showDialog = false}}},[_vm._v("Close")]),_c(VBtn,{attrs:{"color":"blue darken-1","disabled":_vm.loading,"text":""},on:{"click":_vm.handlePrintLegend}},[_vm._v("Generate Legend")])],1)],1),_c('div',{staticStyle:{"display":"none"},attrs:{"id":"printHtml"}},[_c('pre',{domProps:{"textContent":_vm._s(_vm.exportedAnnotations)}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }