<template>
    <v-card>
        <v-card-title>
            <span class="headline">{{ !editMode ? 'Add' : 'Edit' }} Room</span>
        </v-card-title>
        <v-card-text>
            <v-container>
                <v-alert
                    dense
                    outlined
                    :type="hasBlockingIssues ? 'error' : 'warning'"
                    v-if="issues.length"
                >
                    <ul>
                        <li v-for="(issue, i) in issues" :key="i" v-html="issue.issue"></li>
                    </ul>
                </v-alert>
                <v-form v-model="formValid" ref="addRoomForm">
                    <v-select
                        v-if="currentFloorPlan.building"
                        label="Building"
                        :value="currentFloorPlan.building"
                        item-text="name"
                        :items="[currentFloorPlan.building]"
                        disabled
                    />
                    <v-text-field
                        v-if="currentFloorPlan.floor"
                        label="Floor"
                        :value="currentFloorPlan.floor"
                        disabled
                    />
                    <v-text-field
                        v-model="room.room"
                        label="Room"
                        :rules="$rules.required"
                    />
                    <v-text-field
                        v-model="room.sqft"
                        label="SqFt"
                        type="number"
                        :rules="$rules.required"
                    />
                    <!--
                    <v-select
                        label="Shape"
                        v-model="room.shape"
                        item-text="name"
                        :items="roomShapes"
                        return-object
                    />
                    -->
                    <v-text-field
                        v-model="room.name"
                        label="Name (optional)"
                    />
                </v-form>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="reset">Close</v-btn>
            <v-btn
                v-if="!editMode"
                color="blue darken-1"
                text
                :loading="loading"
                :disabled="!allowAdd"
                @click="add"
            >Add Room</v-btn>
            <v-btn
                v-else
                color="blue darken-1"
                text
                :loading="loading"
                :disabled="!allowAdd"
                @click="update"
            >Update</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
//import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
    name: "AddRoomDialog",
    props: {
        curAnnotationType: {
            type: Object
        }
    },
    components: {
    },
    data() {
        return {
            formValid: false,
            loading: false,
            editMode: false,
            editAnnotation: null,
            room: {},
            issues: [],
            roomShapes: [{
                name: 'Rectangle'
            }, {
                name: 'Polygon'
            }]
        };
    },
    computed: {
        ...mapGetters({
            // itemCount: 'space/getItemCount',
            currentAnnotation: 'space/getCurrentFloorPlanAnnotation',
            currentFloorPlan: 'space/getCurrentFloorPlan',
            annotationItemTypes: 'space/getFloorPlanAnnotationItemTypes',
            annotationItems: 'space/getFloorPlanAnnotationItems',
            editAnnotationItem: 'space/getEditAnnotationItem',
        }),
        allowAdd() {
            return (!this.loading && this.formValid && Object.keys(this.room).length);
        },
        hasBlockingIssues() {
            return this.issues.some(x => x.blocking === true);
        },
        roomId() {
            // temp ID until maybe a proper Room model is created
            return `${this.currentFloorPlan.building.id}-${this.currentFloorPlan.floor}-${this.room.room}`;
        }
    },
    methods: {
        reset() {
            this.room = {};
            this.$refs.addRoomForm.resetValidation();
            this.editMode = false;
            this.issues = [];
            this.$emit('closeDialog');
        },
        async add() {
            this.loading = true;

            if (!this.validate()) {
                this.loading = false;
                return;
            }

            const newAnnotation = {
                setHover: true,
                // set the name to a unique value (until we decide to store rooms as a model)
                name: this.roomId,
                meta: {
                    customData: {
                        type: this.curAnnotationType,
                    }
                }
            }

            this.room = {
                id: this.roomId,
                ...this.room,
                ...{
                    building: this.currentFloorPlan.building,
                    floor: this.currentFloorPlan.floor
                }
            };

            newAnnotation.meta.customData.room = this.room;
            
            this.$store.dispatch('space/setAddAnnotation', {
                annotationType: this.curAnnotationType,
                annotation: newAnnotation
            });

            this.reset();
            
            this.loading = false;
        },
        async update() {
            let newCustomData = this.editAnnotationItem.annotation.customData;

            newCustomData.room = {...newCustomData.room, ...this.room};

            this.$store.dispatch('space/setEditAnnotationItem', {annotation: this.editAnnotationItem.annotation.set('customData', newCustomData)});

            this.reset();

        },
        addIssue(issue) {
            if (!this.issues.some(x => x.issue === issue.issue)) this.issues.push(issue);
        },
        validate() {
            this.issues = [];
            this.loading = true;

            // 1. Check if room already exists on annotation
            if (this.annotationItems.some(x => x.meta.customData.room && x.meta.customData.room.id === this.roomId)) {
                this.addIssue({issue: `Room already exists on current floor plan annotation`, blocking: true});
                return false;
            }

            // TODO: various checks on server

            this.loading = false;

            return true;
        },

        loadEdit() {
            this.editMode = true;

            const roomData = this.editAnnotationItem.annotation.customData.room;
            this.room.room = roomData.room;
            this.room.sqft = roomData.sqft;
            this.room.name = roomData.name;
        }
    },
    mounted() {
        //this.room.shape = this.roomShapes[0];
    }
};
</script>