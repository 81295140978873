<template>
    <v-main>
        <v-container fluid style="max-width: 1400px">
            <div class="text-h4">Cartography</div>
            <v-row>
                <v-col xs12 md6>
                    <v-autocomplete
                        v-model="currentFloorPlan"
                        :items="floorPlans"
                        item-text="__str__"
                        item-value="pk"
                        label="Floor Plan"
                        prepend-icon="mdi-map"
                        @change="changeFloorPlan($event, {routeOnly: true})"
                        return-object
                        hide-details
                    ></v-autocomplete>
                </v-col>
                <v-col xs12 md6>
                    <v-select
                        v-model="currentFloorPlanAnnotation"
                        v-if="floorPlanAnnotations.length"
                        :items="floorPlanAnnotations"
                        item-text="__str__"
                        item-value="pk"
                        :loading="loadingAnnotations || loadingAnnotationItems"
                        :disabled="loadingAnnotationItems"
                        label="Annotation"
                        prepend-icon="mdi-note"
                        @change="changeFloorPlanAnnotation($event)"
                        return-object
                        hide-details
                    >
                        <template v-if="isDraft" v-slot:append>
                            <v-chip class="ma-0" small>
                                DRAFT
                            </v-chip>
                        </template>
                    </v-select>
                </v-col>
            </v-row>
            <template v-if="!routeFloorPlanId && recentAnnotations.length">
                <div class="mt-10 text-h5 font-weight-light">Recently Updated Annotations</div>
                <v-divider />
                <v-timeline
                    align-top
                    dense
                >
                    <v-timeline-item
                        small
                        v-for="(item, i) in recentAnnotations"
                        :key="i"
                    >
                        <v-row class="pt-1">
                            <v-col cols="2">
                                <strong>{{ dateDistance(item.created_at) }} ago</strong>
                                <div class="caption" v-if="item.created_by">
                                    By {{ item.created_by.name }}
                                </div>
                            </v-col>
                            <v-col>
                            <router-link :to="{name: 'cartographyFloorPlanAnnotation', params: {floorPlanId: item.floor_plan, annotationId: item.pk}}">
                                <strong>{{ item.__str__ }}</strong>
                            </router-link>
                            <div class="caption" v-if="item.changelog">
                                <span v-if="item.changelog.added">{{ item.changelog.added.length }} added</span>
                                <span v-if="item.changelog.changed">
                                    <span v-if="item.changelog.added && item.changelog.added.length"> / </span>{{ item.changelog.changed.length }} changed</span>
                                <span v-if="item.changelog.removed">
                                    <span v-if="(item.changelog.added && item.changelog.added.length) || (item.changelog.changed && item.changelog.changed.length)"> / </span>
                                    {{ item.changelog.removed.length }} removed
                                </span>
                            </div>
                            </v-col>
                        </v-row>
                    </v-timeline-item>
                </v-timeline>
            </template>
            <template v-else>
                <div v-if="currentFloorPlan != null && !loadingAnnotations && !loadingAnnotationItems" class="mt-2 mb-2">
                    <v-row no-gutters align="center">
                        <v-col cols="5" class="d-flex align--middle">
                            <HoverItemWidget id="hoverItemWidget" />
                        </v-col>
                        <v-col class="d-flex justify-end">
                            <AddAnnotationItemDialog />
                            <v-tooltip bottom open-delay="750">
                                <template v-slot:activator="{on: tooltip}">
                                    <v-btn
                                        v-on="{...tooltip}"
                                        class="ml-2 mr-2"
                                        @click="showFilter = !showFilter"
                                    >
                                        <v-icon>mdi-filter-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Filtering</span>
                            </v-tooltip>
                            <ExportDialog />
                            <ExportAnnotationDialog />
                            <PrintLegendDialog />
                            <SocketChannel
                                @reloadAnnotations="reloadAnnotations"
                                @setHoverItem="setHoverItem"
                            />
                            <SaveDialog
                                ref="saveDialog"
                                @filterSaveAnnotation="filterSaveAnnotation"
                            />
                        </v-col>
                    </v-row>
                    <FilterWidget 
                        ref="filterWidget" 
                        :showFilter="showFilter" 
                        @filterAnnotation="filterAnnotation" 
                        @resetFilter="resetFilter"    
                    />
                </div>

                <CartographyPDFWidget
                    ref="cartographyPdfWidget"
                    @isLoading="emitLoading"
                    @scrollTop="$vuetify.goTo('#hoverItemWidget')"
                />

                <v-row v-show="currentFloorPlanAnnotation" justify="center" class="pt-1">
                    <v-col md="auto"></v-col>
                    <v-col id="capcap" class="d-flex justify-end">
                        <div v-for="(v, k, i) in currentFloorPlanAnnotationItemTypes" :key="i" class="pl-2">
                            <v-avatar :color="v.color" size="25"><v-icon v-text="v.icon" dark small /></v-avatar>
                            <!--<img :src="v.icon" width="20" height="20" class="ma-0 pa-0" style="vertical-align: middle" />-->
                            <span class="caption pl-1" v-text="k" />
                        </div>
                    </v-col>
                </v-row>
                <CartographyDetails @setSelectedAnnotation="setSelectedAnnotationFromCartographyDetailItem" />
            </template>
        </v-container>
    </v-main>
</template>
<script>
    import {mapGetters} from 'vuex';

    import CartographyPDFWidget from '@/components/space/CartographyPDFWidget';
    import AddAnnotationItemDialog from '@/components/space/AddAnnotationItemDialog';
    import FilterWidget from '@/components/space/FilterWidget';
    import ExportDialog from '@/components/space/ExportDialog';
    import ExportAnnotationDialog from '@/components/space/ExportAnnotationDialog';
    import SocketChannel from '@/components/space/SocketChannel';
    import SaveDialog from '@/components/space/SaveDialog';
    import PrintLegendDialog from '@/components/space/PrintLegendDialog';
    import HoverItemWidget from '@/components/space/HoverItemWidget';
    import CartographyDetails from '@/components/space/CartographyDetails';

    export default {
        name: 'Cartography',
        components: {
            CartographyPDFWidget,
            AddAnnotationItemDialog,
            FilterWidget,
            ExportDialog,
            SocketChannel,
            SaveDialog,
            PrintLegendDialog,
            HoverItemWidget,
            CartographyDetails,
            ExportAnnotationDialog
        },
        data: () => ({
            loadingAnnotations: false,
            loadingAnnotationItems: false,
            showFilter: false,
            showHoverDetails: false,
        }),
        computed: {
            ...mapGetters({
                floorPlans: 'space/getFloorPlans',
                floorPlanAnnotations: 'space/getFloorPlanAnnotations',
                currentFloorPlanAnnotationItemTypes: 'space/getCurrentFloorPlanAnnotationItemTypes',
                annotationItems: 'space/getFloorPlanAnnotationItems',
                hoverItem: 'space/getHoverItem',
                isDraft: 'space/getIsDraft',
                initialFilter: 'space/getInitialFilter',
                recentAnnotations: 'space/getRecentAnnotations',
            }),
            currentFloorPlan: {
                get() {
                    return this.$store.getters['space/getCurrentFloorPlan'];
                },
                set(val) {
                    return this.$store.commit('space/SET_CURRENT_FLOOR_PLAN', val)
                }
            },
            currentFloorPlanAnnotation: {
                get() {
                    return this.$store.getters['space/getCurrentFloorPlanAnnotation'];
                },
                set(val) {
                    this.$store.commit('space/SET_CURRENT_FLOOR_PLAN_ANNOTATION', val);
                }
            },
            routeFloorPlanId() {
                return this.$route.params.floorPlanId;
            }
        },
        methods: {
            async changeFloorPlan(floorPlan, options={}) {
                
                if (!floorPlan) return;

                this.$store.dispatch('space/resetCurrentFloorPlan');

                if (!options.initialLoad || options.routeOnly) {

                    try {
                        await this.$router.push({
                            name: 'cartographyFloorPlan',
                            params: {
                                floorPlanId: floorPlan.pk
                            }
                        });
                    } catch (err) {
                        if (err.name !== 'NavigationDuplicated') throw err;
                    }
                    
                    if (options.routeOnly) return;
                }
    
                this.currentFloorPlan = floorPlan;

                this.currentFloorPlanAnnotation = null;

                await this.$store.dispatch('space/getFloorPlanAnnotations', floorPlan.pk);

                if (
                    Array.isArray(this.floorPlanAnnotations) && 
                    this.floorPlanAnnotations.length
                ) {
                    if (!this.$route.params.annotationId) {
                        this.changeFloorPlanAnnotation(this.floorPlanAnnotations[0], {routeOnly: true});
                    }
                } else {
                    /* no annotations, so load the blank floor plan */
                    await this.$refs.cartographyPdfWidget.loadFloorPlan(floorPlan);
                    this.$store.dispatch('space/setItemCount', 0);
                }

            },

            async changeFloorPlanAnnotation(floorPlanAnnotation, options={}) {
                this.loadingAnnotationItems = true;
                const filter = options.filter ? options.filter : {};
                
                if (!options.initialLoad || options.routeOnly) {

                    let hasAnnotationId = !!this.$route.params.annotationId;

                    try {
                        await this.$router.push({
                            name: 'cartographyFloorPlanAnnotation',
                            params: {
                                floorPlanId: this.currentFloorPlan.pk,
                                annotationId: floorPlanAnnotation.pk
                            }
                        });
                    } catch (err) {
                        if (err.name !== 'NavigationDuplicated') throw err;
                    }

                    if (!hasAnnotationId || options.routeOnly) return;

                } else {

                    // redirect to floor plan if annotation not found
                    if (!floorPlanAnnotation) {
                        
                        this.$router.push({
                            name: 'cartographyFloorPlan',
                            params: {
                                floorPlanId: this.currentFloorPlan.pk
                            }
                        });
                        return
                    }
                }

                if (floorPlanAnnotation.pk === -1) {
                    // don't do anything else for floor plans without annotations
                    await this.$refs.cartographyPdfWidget.loadFloorPlan(this.currentFloorPlan);
                    this.$refs.cartographyPdfWidget.setLoadedAnnotations(true);
                    this.loadingAnnotationItems = false;
                    return; 
                }

                // get full detail for this annotation
                await this.$store.dispatch('space/loadFloorPlanAnnotation', floorPlanAnnotation.pk);

                const payload = {
                    annotation: {
                        pk: floorPlanAnnotation.pk,
                        leg: floorPlanAnnotation.leg_annot
                    },
                    filter: {...filter}
                };

                await this.$store.dispatch('space/loadFloorPlanAnnotationItems', payload);

                await this.$refs.cartographyPdfWidget.loadFloorPlan(this.currentFloorPlan, this.currentFloorPlanAnnotation.instant_json);
                this.loadingAnnotationItems = false;
            },

            async filterAnnotation(payload){
                if(this.initialFilter && payload.emptyPayload) return;
                if(payload.emptyPayload) {
                    this.resetFilter();
                    return;
                }
                this.$refs.filterWidget.loading = true;
                
                let result = await this.$store.dispatch('space/filterAnnotationItems', payload);
                if (result.status === 'error'){
                    this.$store.commit('setSnack', { message: result.msg, color: 'error' });
                    this.$refs.filterWidget.loading = false;
                    return;
                }
                await this.$refs.cartographyPdfWidget.filterAnnotationItems(result);
                this.$refs.filterWidget.loading = false;
            },

            async resetFilter(emptyPayload = false){
                if(this.initialFilter && emptyPayload) return;
                this.$refs.filterWidget.loading = true;
                await this.$refs.cartographyPdfWidget.resetFilterAnnotationItems();
                await this.$store.dispatch('space/resetFilter');
                this.$refs.filterWidget.loading = false;
            },
            
            async filterSaveAnnotation() { // Undo filter and reload all annotation items then save
                await this.resetFilter();
                await this.$nextTick(() => {
                    this.$refs.saveDialog.loading = true;
                    this.$refs.saveDialog.saveAnnotation();
                });
            },

            async reloadAnnotations() {
                await this.$store.dispatch('space/getFloorPlanAnnotations', this.currentFloorPlan.pk);

                this.$router.push({name: 'cartographyFloorPlanAnnotation', params: {
                    floorPlanId: this.currentFloorPlan.pk,
                    annotationId: this.floorPlanAnnotations[0].pk
                }});
                
                this.currentFloorPlanAnnotation = this.floorPlanAnnotations[0];

            },

            async setHoverItem(payload) {
                await this.$refs.cartographyPdfWidget.updateHoverItem(payload);
            },

            async loadFloorPlan() {
                this.$store.dispatch('space/setCurrentFloorPlanById', this.routeFloorPlanId);

                this.loadingAnnotations = true;

                await this.changeFloorPlan(this.currentFloorPlan, {initialLoad: true});

                if (this.$route.params.annotationId) {

                    this.$store.dispatch('space/setCurrentFloorPlanAnnotationById', this.$route.params.annotationId);

                    await this.changeFloorPlanAnnotation(this.currentFloorPlanAnnotation, {initialLoad: true});
                }

                this.loadingAnnotations = false;
            },

            async emitLoading(val) {
                if (!this.$refs.saveDialog) return;
                if (val.save) {
                    this.$refs.saveDialog.saveLoading = val.loading;
                    this.$refs.saveDialog.showDialog = val.loading;
                } else {
                    this.$refs.saveDialog.loading = val.loading;
                }
                
                // if (!val.loading && val.save){
                //     this.$refs.saveDialog.saveLoading = false;
                //     this.$refs.saveDialog.showDialog = false;
                // }
            },
            setSelectedAnnotationFromCartographyDetailItem(annotation) {
                this.$refs.cartographyPdfWidget.setSelectedAnnotation(annotation)
            }

        },
        watch: {
            async routeFloorPlanId() {
                if (this.routeFloorPlanId) {
                    await this.loadFloorPlan();
                } else {
                    this.$store.dispatch('space/loadRecentAnnotations');
                }
            }
        },
        async mounted() {
            if (!this.routeFloorPlanId) {
                this.$store.dispatch('space/resetCurrentFloorPlan');
                this.$store.dispatch('space/loadRecentAnnotations');
            }

            await this.$store.dispatch('space/loadFloorPlans');

            if (this.routeFloorPlanId) {
                await this.loadFloorPlan();
            }
        }
    }
</script>