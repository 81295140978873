<template>
    <div class="caption overflow-hidden v-sheet v-sheet--outlined theme--light rounded">
        <v-list-item dense v-if="!hoverItem">
            <v-list-item-avatar class="mr-2" size="25"><v-icon color="grey">mdi-information-outline</v-icon></v-list-item-avatar>
            <v-list-item-content>
            <v-list-item-title class="grey--text">mouse over item for details</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-list-item dense v-else>
            <v-list-item-avatar class="mr-2" size="25">
                <template v-if="hoverItem.icon">
                    <img :src="hoverItem.icon" width="25" height="25" class="ma-0 pa-0" style="vertical-align: middle" />
                </template>
                <template v-else-if="hoverItem.room">
                    <v-icon>mdi-floor-plan</v-icon>
                </template>
            </v-list-item-avatar>
            <template v-if="hoverItem.endpoint">
                <v-list-item-content>
                    <v-list-item-title><a tyle="text-decoration: none" :href="jiraLink(hoverItem.endpoint.jira_key)" target="_blank" v-text="hoverItem.endpoint.__str__" /> &#8211; {{ hoverItem.endpoint.make_model }}</v-list-item-title>
                    <v-slide-y-transition>
                        <v-list-item-subtitle class="font-weight-light" v-if="showHoverDetails">
                            <div v-if="hoverItem.endpoint.location">
                                {{ hoverItem.endpoint.location }}
                            </div>
                            <div v-if="hoverItem.endpoint.last_checkin">
                                Last seen {{ getDateDistance(hoverItem.endpoint.last_checkin) }} ago
                            </div>
                            <div v-else>
                                Never checked in
                            </div>
                        </v-list-item-subtitle>
                    </v-slide-y-transition>
                </v-list-item-content>
                <v-list-item-action class="mt-0 mb-0">
                    <v-btn icon @click="showHoverDetails = !showHoverDetails" small>
                        <v-icon>{{ showHoverDetails ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                    </v-btn>
                </v-list-item-action>
            </template>
            <template v-else-if="hoverItem.person">
                <v-list-item-content>
                    <v-list-item-title>{{ hoverItem.person.name }}</v-list-item-title>
                </v-list-item-content>
            </template>
            <template v-else-if="hoverItem.room">
                <v-list-item-content>
                    <v-list-item-title>
                        {{ hoverItem.room.building.short_name }}-{{ hoverItem.room.floor }}-{{ hoverItem.room.room }}
                        ({{ hoverItem.room.sqft }} sqft.)
                    </v-list-item-title>
                </v-list-item-content>
            </template>
        </v-list-item>
        
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { formatDistanceToNow, parseISO } from 'date-fns';

export default {
    name: 'HoverItemWidget',
    props: {
    },
    data() {
        return {
            showHoverDetails: false
        };
    },
    computed: {
        ...mapGetters({
            hoverItem: 'space/getHoverItem',
        })
    },
    methods: {
        ...mapActions({
        }),
        getDateDistance(dt) {
            return formatDistanceToNow(parseISO(dt));
        }
    }
};
</script>