<template>
    <v-dialog v-model="showDialog" persistent max-width="600px">
        <template v-slot:activator="{on: dialog, attrs}">
            <v-tooltip bottom open-delay="750">
                <template v-slot:activator="{on: tooltip}">
                    <v-btn
                        v-bind="attrs"
                        v-on="{...tooltip, ...dialog}"
                        class="ml-2 mr-2"
                    >
                        <v-icon>mdi-printer</v-icon>
                    </v-btn>
                </template>
                <span>Print Legend</span>
            </v-tooltip>
        </template>
        <v-card>
            <v-card-title class="mb-2">
                <span class="headline">Print Legend</span>
            </v-card-title>
            <v-card-subtitle>
                Please allow popups for this site from your browser.
            </v-card-subtitle>
            <v-card-text>
                <v-container>
                    <v-row v-if="!loading">
                        <v-col cols="6">
                            <v-combobox
                                v-model="exportType"
                                :items="exportTypeOptions"
                                label="Export Type"
                                multiple
                            ></v-combobox>
                        </v-col>
                        <v-col cols="6">
                            <v-select
                                v-model="numCols"
                                :items="cols"
                                label="Number of Columns"
                                return-object
                            ></v-select>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field 
                                label="Title"
                                v-model="title"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row v-if="loading">
                        <v-col cols="12">
                            Generating legend...
                        </v-col>
                        <v-progress-linear
                            indeterminate
                            rounded
                            height="6"
                        ></v-progress-linear>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="showDialog = false">Close</v-btn>
                <v-btn
                    color="blue darken-1"
                    :disabled="loading"
                    text
                    @click="handlePrintLegend"
                >Generate Legend</v-btn>
            </v-card-actions>
        </v-card>
        <div id="printHtml" style="display: none">
            <pre v-text="exportedAnnotations" />
        </div>
    </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";

export default {
    name: "PrintLegendDialog",
    data() {
        return {
            showDialog: false,
            loading: false,
            exportType: ['Computers', 'Printers', 'People'],
            exportTypeOptions: ['Computers', 'Printers', 'People'],
            numCols: '1',
            exportFloorPlan: null,
        };
    },
    computed: {
        ...mapGetters({
            currentFloorPlan: 'space/getCurrentFloorPlan',
            currentFloorPlanAnnotation: 'space/getCurrentFloorPlanAnnotation',
            floorPlanAnnotationItems: 'space/getFloorPlanAnnotationItems',
            annotationExport: 'space/getCurrentAnnotationExport',
            pspdfInstance: 'space/getPSPDFInstance',
        }),
        cols(){
            let arr = [];
            for(let i = 1; i <= 4; i++){
                arr[i-1] = `${i}`;
            }
            return arr;
        },
        exportedAnnotations() {
            if (this.annotationExport.instantJSON && this.annotationExport.instantJSON.annotations) {
                return this.annotationExport.instantJSON; 
            } else {
                return this.annotationExport
            }
        },
        title() {
            return this.currentFloorPlanAnnotation ? this.currentFloorPlanAnnotation.__str__ : '';
        }
    },
    methods: {
        async handlePrintLegend() {
            this.loading = true;
            let exportType = this.exportType.length == 3 ? 'all' : this.exportType;
            let exportTypeString = this.exportType.join(', ').replace(/, ([^,]*)$/, ' and $1');
            let eps = [];
            
            if (!this.exportedAnnotations.annotations.length) {
                this.$store.commit('setSnack', { message: `Can not create legend. There are no annotations on the floor plan.`, color: 'error', timeout: 5000});
                this.loading = false;
                this.showDialog = false;
                return;
            }

            if(exportType === 'all'){
                eps = this.exportedAnnotations.annotations.filter(e => {
                    return !e.customData.room
                });
            } else if(exportType.length >= 1){
                exportType.forEach(e => {
                    let temp;
                    if(e === 'Computers'){
                        temp = this.exportedAnnotations.annotations.filter(ep => {
                            if (ep.customData.endpoint){
                                return ['Computer', 'Thin Client'].includes(ep.customData.type.name);
                            }
                        });
                    }else if(e === 'Printers'){
                        temp = this.exportedAnnotations.annotations.filter(ep => {
                            if (ep.customData.endpoint){
                                return ep.customData.type.name.includes('Printer');
                            } 
                        });
                    }else if(e === 'People'){
                        temp = this.exportedAnnotations.annotations.filter(ep => {
                            if(ep.customData.person){
                                return true;
                            }
                        });
                    }
                    eps = eps.concat(temp);
                });
            } else {
                this.$store.commit('setSnack', { message: 'Error generating legend, please choose a type of endpoint.', color: 'error', timeout: 5000 });
                this.loading = false;
                this.showDialog = false;
                return;
            }
            if(eps.length == 0){
                this.$store.commit('setSnack', { message: `There are no ${exportTypeString} on the floor plan.`, color: 'error', timeout: 5000});
                this.loading = false;
                this.showDialog = false;
                return;
            }
            let legendHTML = '';
            for(let item of eps){
                let iconImg = await this.getImageData(item);
                let name = '';
                if(item.customData.person){
                    name = item.customData.person.name;
                }else{
                    name = item.customData.endpoint.hostname;
                }
                let tempHTML = `<div><img style="vertical-align: middle;width:25px;height:25px;" src="${iconImg}"/><span style="margin-left:10px; display: inline-block;">${name}</span></div>`;
                legendHTML += tempHTML;
            }
            this.$store.commit('setSnack', { message: 'Legend generated. Please allow popups for this site if you don\'t see a new window open.', color: 'success', timeout:5000 });
            this.showDialog = false;
            this.loading = false;
            this.renderLegend(legendHTML);

        },
        async getImageData(item) {
            if (!this.exportedAnnotations.attachments) {
                const imgData = await this.pspdfInstance.getAttachment(item.imageAttachmentId);
                const imgDataURL = await this.readBlobAsDataURL(imgData);
                return imgDataURL;
            } else {
                return `data:image/png;base64,${this.exportedAnnotations.attachments[item.imageAttachmentId].binary}`;
            }
        },
        renderLegend(payload){
            const printWin = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
            printWin.document.write(`<!DOCTYPE html>
            <html>
                <head>
                </head>
                <body>
                    <h3 style="margin:10px 0">${this.title}</h3>
                    <div style="display: grid;grid-template-columns:repeat(${this.numCols}, 1fr);row-gap: 10px;">
                        ${payload}
                    </div>  
                </body>
            </html>`);
            printWin.document.close();
            printWin.focus();
            printWin.print();
            printWin.close();
        }
    },
};
</script>