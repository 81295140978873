<template>
    <v-dialog
        v-model="showDialog"
        max-width="600px"
        persistent>
        <template v-slot:activator="{on: dialog, attrs}">
            <v-tooltip bottom open-delay="750">
                <template v-slot:activator="{on: tooltip}">
                    <v-btn  
                        v-bind="attrs"
                        v-on="{...tooltip, ...dialog}"
                        class="ml-2 mr-2"
                    >
                        <v-icon>mdi-export-variant</v-icon>
                    </v-btn>
                </template>
                <span>Export CSV Report</span>
            </v-tooltip>
        </template>
        <v-card>
            <v-card-title>
                <span class="headline">Export CSV Report</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row v-if="!loading">
                        <v-col cols="12">
                            <v-combobox
                                v-model="exportType"
                                :items="exportTypeOptions"
                                label="Export Type"
                                multiple
                            ></v-combobox>
                        </v-col>
                        <v-col cols="12">
                            <v-select
                                v-model="exportFloorPlan"
                                :items="exportFloorPlanOptions"
                                label="Floor Plan"
                                item-text="__str__"
                                return-object
                            ></v-select>
                        </v-col>
                        <v-col cols="12">
                            <v-combobox
                                v-model="exportDivision"
                                :items="exportDivisionOptions"
                                item-text="name"
                                label="Division(s)"
                                multiple
                                return-object
                            ></v-combobox>
                        </v-col>
                    </v-row>
                    <v-row v-if="loading">
                        <v-col cols="12">
                            Generating report...
                        </v-col>
                        <v-progress-linear
                            indeterminate
                            rounded
                            height="6"
                        ></v-progress-linear>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="showDialog = false">Close</v-btn>
                <v-btn
                    color="blue darken-1"
                    :disabled="loading"
                    text
                    @click="handleExport"
                >Export</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import axios from 'axios';
import { mapGetters, mapActions } from "vuex";

export default {
    name: "ExportDialog",
    data() {
        return {
            showDialog: false,
            loading: false,
            exportDivision: [],
            exportType: ['Computers', 'Printers'],
            exportTypeOptions: ['Computers', 'Printers'],
            exportFloorPlan: null
        };
    },
    computed: {
        ...mapGetters({
            currentFloorPlan: 'space/getCurrentFloorPlan',
            currentFloorPlanAnnotation: 'space/getCurrentFloorPlanAnnotation',
            divisionOptions: 'population/getDivisionList'
        }),
        exportFloorPlanOptions() {
            return [this.currentFloorPlanAnnotation, {__str__: 'All Floor Plans', pk: -1}];
        },
        exportDivisionOptions() {
            return [{id: -1, name: 'All Divisions'}, ...this.divisionOptions];
        },
        exportDivisionIds() {
            return this.exportDivision.map(d => d.id);
        }
    },
    methods: {
        ...mapActions({
            'loadDivisions': 'population/loadDivisions'
        }),
        async handleExport() {
            this.loading = true;
            const response = await axios.get(`/inventory/export_floor_plan_endpoints/${this.exportFloorPlan.pk}/${this.exportDivisionIds}/${this.exportType}/`, {responseType: 'blob'});

            // some weird trickery to get teh browser to download the response as file

            if (!window.navigator.msSaveOrOpenBlob){
                const contentDisposition = response.headers['content-disposition'];
                
                const url = URL.createObjectURL(new Blob([response.data],{type:response.headers['content-type']}));
                const link = document.createElement('a');
                const fileName = contentDisposition.match(/filename=(?<filename>[^,;]+)/)[1];
                
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                // 
                // TODO: this could be handled with something like:
                // let blob = new Blob([data], { type: response.headers['content-type'] });
                // window.navigator.msSaveOrOpenBlob(blob, fileName);
                // but for now, too bad
                //
                alert('Fail - what version of IE are you using?');
            }
            this.loading = false;
        }
    },
    created() {
        this.exportFloorPlan = this.currentFloorPlanAnnotation;
        this.exportDivision = [this.exportDivisionOptions[0]];
        this.loadDivisions();
    }
};
</script>