<template>
    <v-dialog
        v-model="showDialog"
        max-width="620px"
        v-if="numConnected > 1 || showDialog"
    >
        <template v-slot:activator="{on: dialog, attrs}">
            <v-tooltip
                v-model="showTooltip"
                bottom
            >
                <template v-slot:activator="{on: tip}">
                    <v-badge
                        color="warning"
                        icon="mdi-autorenew"
                        overlap
                        :value="liveMode"
                        offset-x="18"
                    >
                        <v-btn
                            v-bind="attrs"
                            v-on="{...tip, ...dialog}"
                            class="ml-2 mr-2"
                            @click="scrollChatBottom"
                        >
                            {{ numConnected }} <v-icon>mdi-account-multiple</v-icon>
                        </v-btn>
                    </v-badge>
                </template>
                <span><v-icon v-if="tooltip.icon" color="white" v-text="tooltip.icon" left />{{ tooltip.text || defaultTooltipText }}</span>
            </v-tooltip>
        </template>
        <v-card v-if="dialogToShow === 'live_mode'">
            <v-card-title>
                <span class="headline">Live Mode</span>
            </v-card-title>
            <v-card-text>
                <v-alert type="warning" outlined>
                    {{ currentFloorPlanAnnotation.__str__ }} was just changed by {{ dialogContent.user.full_name }}<br><br><strong>Do you want to enable live mode to see changes in real-time?</strong>
                </v-alert>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="resetDialog">No, Continue Editing</v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="enableLiveMode"
                >Enable Live Mode</v-btn>
            </v-card-actions>
        </v-card>
        <v-card v-else-if="dialogToShow === 'annotation_save'">
            <v-card-title>
                <span class="headline">New Annotation Available</span>
            </v-card-title>
            <v-card-text>
                <v-alert type="warning" outlined>
                    There is a new annotation for this floor plan by {{ dialogContent.user.full_name }}:<br><br>
                    <strong v-text="dialogContent.newAnnotation.name" /><br><br>
                    If you save now, this annotation may not include changes made by the above annotation.
                </v-alert>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="resetDialog">Continue Editing</v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="reloadAnnotations"
                >Load New Annotation</v-btn>
            </v-card-actions>
        </v-card>
        <v-card v-else>
            <v-card-title>
                <span class="headline">Chat</span>
            </v-card-title>
            <v-card-text>
                <v-row no-gutters>
                    <v-col style="border-right: 1px dashed rgba(0, 0, 0, 0.12)">
                        <v-list dense class="ma-0 pa-0">
                            <v-subheader class="ma-0 pa-0">{{ numConnected }} Connected</v-subheader>
                            <v-list-item v-for="(user, v, i) in connectedUsers" :key="i" class="ma-0 pa-0" style="min-height: auto">
                                <v-list-item-avatar size="20" class="pa-0 ma-0">
                                    <v-icon small>mdi-account</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-title>{{ user.full_name }}<span class="font-weight-light" v-if="user.connections && user.connections > 1"> x {{ user.connections }}</span></v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-col>
                    <v-col cols="9" class="pl-2">
                        <div class="ma-0 pa-0" style="overflow-y: auto; min-height: 200px; max-height: 350px" id="chatBox">
                            <template v-if="allMessages.length">
                                <transition-group name="fade">
                                <template v-for="(msg, i) in allMessages">
                                    
                                    <div :key="i">
                                        <v-row :key="i" no-gutters>
                                            <v-col><strong v-text="msg.user.full_name" /></v-col>
                                            <v-col class="text-end text-caption" :title="msg.timestamp">{{ getDateDistance(msg.timestamp) }} ago</v-col>
                                        </v-row>
                                        {{ msg.message }}
                                        <v-divider
                                            class="mt-2 mb-2"
                                            v-if="i < allMessages.length - 1"
                                        ></v-divider>
                                    </div>
                                    
                                </template>
                                </transition-group>
                            </template>
                            <template v-else>
                                <v-container style="height: 200px;">
                                    <v-row
                                        class="fill-height"
                                        align-content="center"
                                        justify="center"
                                    >
                                        <v-col class="subtitle-1 text-center" cols="12">
                                            <v-icon>mdi-chat-outline</v-icon> no messages to show!
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </template>
                        </div>
                    </v-col>
                </v-row>
                <v-text-field
                    v-model="message"
                    label="Send a message"
                    append-icon="mdi-send"
                    @click:append="sendMessage"
                    @keyup.enter="sendMessage"
                    hide-details
                />
            </v-card-text>
            <v-card-actions>
                <v-switch
                    v-model="liveMode"
                    label="Live Mode"
                    class="mb-2"
                    hide-details
                ></v-switch>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    @click="resetDialog"
                    text>Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import { formatDistanceToNow, parseISO } from 'date-fns';

export default {
    name: "SocketChannel",
    data() {
        return {
            showDialog: false,
            dialogToShow: null,
            dialogContent: {},
            loading: false,
            notes: null,
            connection: null,
            showTooltip: false,
            tooltip: {
                icon: null,
                text: null
            },
            message: null,
            allMessages: [],
            numConnected: 0,
            connectedUsers: {},
            defaultTooltipText: 'Current Users',
            tooltipText: null,
            liveMode: false,
            pendingPayload: null,
            pendingSave: false,
        };
    },
    computed: {
        ...mapGetters({
            user: 'user',
            currentFloorPlan: 'space/getCurrentFloorPlan',
            currentFloorPlanAnnotation: 'space/getCurrentFloorPlanAnnotation',
            lastAnnotationSaved: 'space/getLastAnnotationSaved',
            currentAnnotationExport: 'space/getCurrentAnnotationExport',
            multiUserAnnotations: 'space/getMultiUserAnnotations',
            annotationItems: 'space/getFloorPlanAnnotationItems',
            focusedAnnotationItem: 'space/getFocusedAnnotationItem',
            pspdfInstance: 'space/getPSPDFInstance',
            isFiltered: 'space/getFilterState',
            filterPayload: 'space/getFilterPayload'
        }),
    },
    methods: {
        connect() {
            let url = new URL(`/ws/cartography/${this.currentFloorPlan.pk}/${this.currentFloorPlanAnnotation.pk}/?token=${this.$store.getters['token']}`, window.location.href);
            url.protocol = url.protocol.replace('http', 'ws');

            this.connection = new WebSocket(url.href);
            this.connection.onmessage = (data) => this.handleEvent(data);

        },

        async handleEvent(e) {
            const payload = JSON.parse(e.data);
            const isOwnEvent = this.user.username === payload.user.username;

            switch (payload.type) {
                case 'user_join':
                case 'user_leave':
                    this.numConnected = payload.num_users;
                    this.connectedUsers = payload.connected_users;

                    if (!isOwnEvent) {
                        this.tooltip.icon = payload.type === 'user_join' ? 'mdi-account-arrow-right' : 'mdi-account-arrow-left';
                        this.tooltip.text = payload.message;
                        this.showTooltip = true;
                        this.resetTooltip();
                    }
                    if (this.numConnected > 1) {
                        this.$store.dispatch('space/addRemoveMultiUserAnnotations', {
                            item: this.currentFloorPlanAnnotation.pk
                        });
                    } else if (this.multiUserAnnotations.includes(this.currentFloorPlanAnnotation.pk) && this.numConnected <= 1) {
                        this.$store.dispatch('space/addRemoveMultiUserAnnotations', {
                            remove: true,
                            item: this.currentFloorPlanAnnotation.pk
                        });
                    }

                    break;

                case 'annotation_export_update':
                    if (!isOwnEvent) {
                        if (!this.liveMode) {
                            this.showDialog = true;
                            this.dialogToShow = 'live_mode';
                            this.dialogContent = {
                                user: payload.user
                            };
                            this.pendingPayload = payload.payload;
                        } else {
                            this.$store.dispatch('space/setApplyAnnotationExport', payload.payload);
                        }
                    }
                    break;
                
                case 'message':
                    this.allMessages.push({
                        ...payload.payload, user: {...payload.user}
                    });

                    if (!this.showDialog) this.showDialog = true;

                    this.scrollChatBottom();

                    break;

                case 'annotation_save':
                    if (!isOwnEvent) {
                        this.showDialog = true;
                        this.dialogToShow = 'annotation_save';
                        this.dialogContent = {
                            user: payload.user,
                            newAnnotation: {
                                name: payload.payload.__str__
                            }
                        };
                    }

                    break;
                
                case 'item_focus':
                    if (!isOwnEvent && this.liveMode) {
                        const payloadCD = payload.payload.customData;
                        let itemToFocus = {};
                        
                        if (payloadCD.endpoint) {
                            itemToFocus = this.annotationItems.find(x => x.meta.customData.endpoint && x.meta.customData.endpoint.id == payloadCD.endpoint.id);
                        } else if (payloadCD.person) {
                            itemToFocus = this.annotationItems.find(x => x.meta.customData.person && x.meta.customData.person.id == payloadCD.person.id);
                        } else if (payloadCD.room) {
                            itemToFocus = this.annotationItems.find(x => x.meta.customData.room && x.meta.customData.room.id == payloadCD.room.id);
                        }
                        if (itemToFocus.id) {
                            this.pspdfInstance.setSelectedAnnotation(itemToFocus.id);
                            this.$emit('setHoverItem', payload.payload);
                        }
                    }
                    break;
            }
        },

        async resetTooltip() {
            await new Promise(resolve => setTimeout(resolve, 4000));
            this.showTooltip = false;
            await new Promise(resolve => setTimeout(resolve, 1000));
            if (!this.showTooltip) {
                this.tooltip.icon = null;
                this.tooltip.text = null;
            }
        },

        resetDialog() {
            this.showDialog = false;
            this.dialogToShow = null;
        },

        enableLiveMode() {
            this.liveMode = true;
            this.resetDialog();
            if (this.pendingPayload) this.$store.dispatch('space/setApplyAnnotationExport', this.pendingPayload);
        },

        sendMessage() {
            if (this.message) {
                this.connection.send(JSON.stringify({
                    type: 'message',
                    message: this.message,
                    timestamp: new Date().toISOString()
                }));
                this.message = null;
            }
        },

        getDateDistance(dt) {
            return formatDistanceToNow(parseISO(dt));
        },

        async scrollChatBottom() {
            await this.$nextTick();
            let cb = document.getElementById("chatBox");
            if (cb) cb.scrollTop = cb.scrollHeight - cb.clientHeight;
        },
        
        reloadAnnotations() {
            this.$emit('reloadAnnotations');
            this.resetDialog();
        }
    },
    watch: {
        async currentFloorPlanAnnotation(val) {
            if (val) {
                if (this.pendingSave) {
                    await this.connection.send(JSON.stringify({
                        type: 'annotation_save',
                        payload: this.currentFloorPlanAnnotation
                    })); // only send the first one (no need to iterate for now)
                    this.pendingSave = false; // clear queue
                }
                // close and facilitate a new connection with the new annotation id
                if (this.connection) this.connection.close();
                this.connect();
            }
        },

        lastAnnotationSaved() {
            if (this.numConnected > 1 && this.connection) this.pendingSave = true;
        },
        
        currentAnnotationExport(val) {
            if (this.connection && !val.initialLoad) {
                let data = val
                data.isFiltered = this.isFiltered;
                if(this.isFiltered){
                    data.filterPayload = this.filterPayload;
                }
                this.connection.send(JSON.stringify({
                    type: 'annotation_export',
                    payload: data
                }));
            }
        },

        focusedAnnotationItem(val) {
            if (this.connection && this.numConnected > 1) {
                this.connection.send(JSON.stringify({
                    type: 'set_item_focus',
                    payload: val
                }));
            }
        },
    },
    beforeDestroy() {
        if (this.connection) this.connection.close();
    },
    mounted() {
        this.connect();
    }
};
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: opacity 1s;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}
</style>