import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"headline"},[_vm._v("Add Person")])]),_c(VCardText,[_c(VContainer,[(_vm.issues.length)?_c(VAlert,{attrs:{"dense":"","outlined":"","type":_vm.hasBlockingIssues ? 'error' : 'warning'}},[_c('ul',_vm._l((_vm.issues),function(issue,i){return _c('li',{key:i,domProps:{"innerHTML":_vm._s(issue.issue)}})}),0)]):_vm._e(),_c(VForm,{ref:"addPersonForm",model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('SearchPeople',{ref:"searchPeopleWidget",attrs:{"label":"Search people","ldap-populate":"","employee-populate":"","employee-populate-create-button":false,"hide-details":false},on:{"input":_vm.validate},model:{value:(_vm.person),callback:function ($$v) {_vm.person=$$v},expression:"person"}}),(_vm.currentFloorPlan.building)?_c(VSelect,{attrs:{"label":"Building","value":_vm.currentFloorPlan.building,"item-text":"name","items":[_vm.currentFloorPlan.building],"disabled":""}}):_vm._e(),(_vm.currentFloorPlan.floor)?_c(VTextField,{attrs:{"label":"Floor","value":_vm.currentFloorPlan.floor,"disabled":""}}):_vm._e(),_c(VTextField,{attrs:{"label":"Room","rules":_vm.$rules.required},model:{value:(_vm.personLocation.room),callback:function ($$v) {_vm.$set(_vm.personLocation, "room", $$v)},expression:"personLocation.room"}})],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.reset}},[_vm._v("Close")]),_c(VBtn,{attrs:{"color":"blue darken-1","text":"","loading":_vm.loading,"disabled":!_vm.allowAdd},on:{"click":_vm.add}},[_vm._v("Add Person")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }