import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"headline"},[_vm._v("Add "+_vm._s(_vm.curAnnotationType.name)+" Endpoint")])]),_c(VCardText,[_c(VContainer,[(_vm.issues.length)?_c(VAlert,{attrs:{"dense":"","outlined":"","type":_vm.hasBlockingIssues ? 'error' : 'warning'}},[_c('ul',_vm._l((_vm.issues),function(issue,i){return _c('li',{key:i,domProps:{"innerHTML":_vm._s(issue.issue)}})}),0)]):_vm._e(),_c(VAutocomplete,{attrs:{"label":"Search tag #","prepend-icon":"mdi-magnify","search-input":_vm.search,"items":_vm.items,"loading":_vm.loading,"item-text":"summary","item-value":"pk","hide-selected":"","hide-no-data":"","return-object":"","autofocus":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"change":_vm.validateEndpoint},model:{value:(_vm.endpoint),callback:function ($$v) {_vm.endpoint=$$v},expression:"endpoint"}})],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.reset}},[_vm._v("Close")]),_c(VBtn,{attrs:{"color":"blue darken-1","text":"","loading":_vm.loading,"disabled":!_vm.allowAddEndpoint},on:{"click":_vm.addEndpointToAnnotation}},[_vm._v("Add Endpoint")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }