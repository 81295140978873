<template>
    <v-card>
        <v-card-title>
            <span class="headline">Add {{ curAnnotationType.name }} Endpoint</span>
        </v-card-title>
        <v-card-text>
            <v-container>
                <v-alert
                    dense
                    outlined
                    :type="hasBlockingIssues ? 'error' : 'warning'"
                    v-if="issues.length"
                >
                    <ul>
                        <li v-for="(issue, i) in issues" :key="i" v-html="issue.issue"></li>
                    </ul>
                </v-alert>
                <v-autocomplete
                    label="Search tag #"
                    prepend-icon="mdi-magnify"
                    v-model="endpoint"
                    :search-input.sync="search"
                    :items="items"
                    :loading="loading"
                    item-text="summary"
                    item-value="pk"
                    hide-selected
                    hide-no-data
                    return-object
                    @change="validateEndpoint"
                    autofocus
                >
                </v-autocomplete>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="reset">Close</v-btn>
            <v-btn
                color="blue darken-1"
                text
                @click="addEndpointToAnnotation"
                :loading="loading"
                :disabled="!allowAddEndpoint"
            >Add Endpoint</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
    name: 'AddEndpointDialog',
    props: {
        curAnnotationType: {
            type: Object
        }
    },
    data() {
        return {
            endpoint: null,
            loading: false,
            search: null,
            items: [],
            issues: [],
            newItemCount: null,
            newAnnotationIcon: null
        };
    },
    computed: {
        ...mapGetters({
            itemCount: 'space/getItemCount',
            currentFloorPlan: 'space/getCurrentFloorPlan',
            currentFloorPlanAnnotation: 'space/getCurrentFloorPlanAnnotation',
            floorPlanAnnotationItemTypes: 'space/getFloorPlanAnnotationItemTypes',
            floorPlanAnnotationItems: 'space/getFloorPlanAnnotationItems',
        }),
        hasBlockingIssues() {
            return this.issues.some(x => x.blocking === true);
        },
        allowAddEndpoint() {
            if (this.loading) return false;
            if (!this.endpoint) return false;
            if (this.hasBlockingIssues) return false;
            return true;
        },
        endpointTypeUrl() {
            if (this.curAnnotationType.name === 'Computer') return '&type=computers';
            if (this.curAnnotationType.name === 'Printer / MFP') return '&type=printers';
            if (this.curAnnotationType.name === 'Thin Client') return '&type=thinclients';
            if (this.curAnnotationType.name === 'RAP') return '&type=raps'
            return '';
        }
    },
    methods: {
        addIssue(issue) {
            if (!this.issues.some(x => x.issue === issue.issue)) this.issues.push(issue);
        },
        async validateEndpoint() {
            this.issues = [];
            this.loading = true;
            // 1. Check if endpoint already exists on floor plan annotation
            if (this.floorPlanAnnotationItems.some(x => x.meta.customData.endpoint && x.meta.customData.endpoint.id === this.endpoint.pk)) {
                this.addIssue({issue: `<strong>${this.endpoint.__str__}</strong> already exists on current floor plan annotation`, blocking: true})
            }

            // 2. Check various issues on the server
            const response = await axios.get(`/inventory/add_fp_endpoint_item_check/${this.endpoint.pk}/${this.currentFloorPlan.pk}/${this.currentFloorPlanAnnotation.pk}/`);

            if (response.data.issues.length) this.issues = [...this.issues, ...response.data.issues];

            this.loading = false;
        },
        async addEndpointToAnnotation() {

            const newAnnotation = {
                setHover: true,
                //icon: this.endpoint.icon,
                name: `endpoint${this.endpoint.pk}`,
                meta: {
                    customData: {
                        endpoint: {
                            id: this.endpoint.pk,
                            index: this.itemCount + 1,
                            hostname: this.endpoint.__str__,
                            type: this.endpoint.type,
                            jira_key: this.endpoint.jira_key,
                            os_name: this.endpoint.os_name,
                            division: this.endpoint.division
                        },
                        type: this.curAnnotationType,
                    }
                }
            };

            // update store
            this.$store.dispatch('space/setAddAnnotation', {
                annotationType: this.curAnnotationType,
                annotation: newAnnotation
            });
            
            this.reset();
        },
        reset() {
            this.items = [];
            this.issues = [];
            this.search = null;
            this.endpoint = null;
            this.$emit('closeDialog');
        }
    },
    watch: {
        async search(val) {

            if (this.loading || !val) return;

            if (this.endpoint && val === this.endpoint.summary) return;

            // strip out everything except for numbers (this essentially breaks any model without indexed hostnames)
            const numVal = val.replace(/\D/g, '');

            if (!numVal || numVal.length < 3) return;
            
            this.loading = true;
            let url = `/inventory/endpoint_search/${numVal}/?count=${this.itemCount}${this.endpointTypeUrl}`;
            const response = await axios.get(url);
            this.items = response.data.endpoints;
            if (!this.items.length) this.endpoint = null;
            this.loading = false;
        }
    }
};
</script>