<template>
    <div v-if="currentFloorPlan && currentFloorPlanAnnotation">
        <v-tabs v-model="tab">
            <v-tab key="detail">Details</v-tab>
            <v-tab key="items">{{ itemsLabel }}</v-tab>
            <v-tab key="changelog" v-if="currentFloorPlanAnnotation.changelog">Changelog</v-tab>
        </v-tabs>
        
        <v-container fluid style="max-width: 1400px">
            <v-tabs-items v-model="tab">
                <v-tab-item key="detail">
                    <template v-if="currentFloorPlanAnnotation.notes">
                        <h4>Notes</h4>
                        <div>{{ currentFloorPlanAnnotation.notes }}</div>
                    </template>
                    <template v-else>
                        <em>No notes available for this annotation</em>
                    </template>
                    <div v-if="currentFloorPlanAnnotation.created_by" class="caption mt-2 grey--text text--darken-2">
                        Created <span class="font-weight-bold" :title="currentFloorPlanAnnotation.created_at" v-text="getDateDistance(currentFloorPlanAnnotation.created_at)" /> ago by <span class="font-weight-bold" v-text="currentFloorPlanAnnotation.created_by.name" />
                    </div>
                </v-tab-item>
                <v-tab-item key="items">
                    <div v-for="(groupSet, group, i) in groupedItems" :key="i">
                        <div class="overline" v-text="group.replace('Person', 'People')" />
                        <v-divider />
                        <v-list dense>
                            <v-list-item 
                                v-for="(item, i2) in groupSet" 
                                :key="i2" 
                                @click="$emit('setSelectedAnnotation', item);"
                            >
                                <v-list-item-icon>
                                    <template v-if="item.icon">
                                        <img :src="item.icon" width="25" height="25" class="ma-0 pa-0" style="vertical-align: middle" />
                                    </template>
                                    <template v-else>
                                        <v-icon>mdi-floor-plan</v-icon>
                                    </template>
                                </v-list-item-icon>
                                <v-list-item-title>
                                    <AnnotationItem :item="item.meta" :show-icon="false" />
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </div>
                </v-tab-item>
                <v-tab-item v-if="currentFloorPlanAnnotation.changelog" key="changelog">
                    
                    <div v-if="currentFloorPlanAnnotation.changelog.added">
                        <v-list>
                            <v-subheader>ADDED</v-subheader>
                            <v-list-item v-for="(logItem, i) in currentFloorPlanAnnotation.changelog.added" :key="i">
                                <AnnotationItem :item="logItem" />
                            </v-list-item>
                        </v-list>
                    </div>

                    <v-divider
                        v-if="currentFloorPlanAnnotation.changelog.added && (currentFloorPlanAnnotation.changelog.removed || currentFloorPlanAnnotation.changelog.changed)"
                    />


                    <div v-if="currentFloorPlanAnnotation.changelog.removed">
                        <v-list>
                            <v-subheader>REMOVED</v-subheader>
                            <v-list-item v-for="(logItem, i) in currentFloorPlanAnnotation.changelog.removed" :key="i">
                                <AnnotationItem :item="logItem" />
                            </v-list-item>
                        </v-list>
                    </div>

                    <v-divider
                        v-if="currentFloorPlanAnnotation.changelog.changed && currentFloorPlanAnnotation.changelog.added && currentFloorPlanAnnotation.changelog.removed"
                    />

                    <div v-if="currentFloorPlanAnnotation.changelog.changed">
                        <v-list>
                            <v-subheader>CHANGED</v-subheader>
                            <v-list-item v-for="(logItem, i) in currentFloorPlanAnnotation.changelog.changed" :key="i">
                                <AnnotationItem :item="logItem.item" />
                            </v-list-item>
                        </v-list>
                    </div>
                    
                </v-tab-item>
            </v-tabs-items>
        </v-container>
    </div>
</template>
<script>
    import {mapGetters} from 'vuex';
    import { formatDistanceToNow, parseISO } from 'date-fns';

    import AnnotationItem from '@/components/space/AnnotationItem';

    export default {
        name: 'CartographyDetails',
        components: {
            AnnotationItem
        },
        data: () => ({
            tab: null,
            groupedItems: [],
        }),
        computed: {
            ...mapGetters({
                currentFloorPlan: 'space/getCurrentFloorPlan',
                currentFloorPlanAnnotation: 'space/getCurrentFloorPlanAnnotation',
                annotationItems: 'space/getFloorPlanAnnotationItems',
                annotationExport: 'space/getCurrentAnnotationExport',
                pspdfInstance: 'space/getPSPDFInstance',
            }),
            exportedAnnotations() {
                if (this.annotationExport.instantJSON && this.annotationExport.instantJSON.annotations) {
                    return this.annotationExport.instantJSON; 
                } else {
                    return this.annotationExport
                }
            },
            itemsLabel() {
                return this.annotationItems.length !== 1 ? `${this.annotationItems.length} Items` : `${this.annotationItems.length} Item`;
            }
        },
        methods: {
            getDateDistance(dt) {
                return formatDistanceToNow(parseISO(dt));
            },
            async getImageData(annotationId) {
                if (this.exportedAnnotations.annotations) {
   
                    const annotation = this.exportedAnnotations.annotations.find(x => x.id === annotationId);
                    
                    if (annotation && annotation.imageAttachmentId) {
                    
                        if (!this.exportedAnnotations.attachments) {
                            const imgData = await this.pspdfInstance.getAttachment(annotation.imageAttachmentId);
                            const imgDataURL = await this.readBlobAsDataURL(imgData);
                            return imgDataURL;
                        } else {
                            return `data:image/png;base64,${this.exportedAnnotations.attachments[annotation.imageAttachmentId].binary}`;
                        }
                    }
                }
            },
        },
        watch: {
            async annotationExport() {
                const items = await this.annotationItems.reduce(async (r, a) => {
                    r = await r;
                    r[a.meta.customData.type.name] = [...r[a.meta.customData.type.name] || [], {icon: await this.getImageData(a.id), ...a}];
                    return await r;
                }, {});
                this.groupedItems = items;
            }
        }
    }
</script>